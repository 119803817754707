function combineInitialAndServerValues(initialDiversityValues, serverValues) {
  const initialValues = initialDiversityValues.map((diversityValue) => {
    return { ...diversityValue, percentage: 0 };
  });

  return initialValues.map((initialValue) => {
    if (!serverValues) {
      return initialValue;
    }

    const serverValue = serverValues.find(
      (entity) => entity.name === initialValue.name,
    );

    if (serverValue) {
      return { ...initialValue, ...serverValue };
    }

    return initialValue;
  });
}
export const getSiteRaces = (siteData, races) => {
  return combineInitialAndServerValues(races, siteData.races);
};

export const getSiteEthnicities = (siteData, ethnicities) => {
  return combineInitialAndServerValues(ethnicities, siteData.ethnicities);
};

export const getSiteSexes = (siteData, sexes) => {
  return combineInitialAndServerValues(sexes, siteData.sexes);
};
