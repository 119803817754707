import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Form, notification, Row } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import util from "utils/util";
import OrganizationService from "../services/organizationService";
import { saveOrganization } from "../features/organization";
import useApplyAllModal from "../hooks/useApplyAllModal";
import InquiryInformation from "../pages/dashboard/OrgProfile/OrgProfileForm/InquiryInformation";
import DescriptionField from "js/pages/dashboard/OrgProfile/OrgProfileForm/DescriptionField";
import WebsiteField from "js/pages/dashboard/OrgProfile/OrgProfileForm/WebsiteField";
import LogoField from "js/pages/dashboard/OrgProfile/OrgProfileForm/LogoField";
import UniformLogoField from "js/pages/dashboard/OrgProfile/OrgProfileForm/UniformLogoField";
import OrganizationNameField from "js/pages/dashboard/OrgProfile/OrgProfileForm/OrganizationNameField";
import DisplayNameField from "js/pages/dashboard/OrgProfile/OrgProfileForm/DisplayNameField";

function EditOrgProfile(props) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  let organizationData = useSelector((state) => state.organization);
  const [logo, setLogo] = useState();
  const [confirm, contextHolder] = useApplyAllModal(form, organizationData);
  const [wordCount, setWordCount] = useState(0);

  const publicDisplayName = Form.useWatch("publicDisplayName", form);
  const organizationWebsiteUrl = Form.useWatch("organizationWebsiteUrl", form);
  const organizationDescription = Form.useWatch(
    "organizationDescription",
    form,
  );

  const saveOrgData = (values) => {
    props.setLoaderLoading(true);
    let payload = util.getOrgProfilePayload(values, organizationData);
    OrganizationService.saveOrganizationDetails(payload)
      .then(() => {
        dispatch(saveOrganization(payload));
        props.handleClick();
        props.setStep2(true);
      })
      .catch(() => {
        notification.error({
          message: "Error while saving Organization profile !!!",
        });
        props.setLoaderLoading(true);
      });
  };

  useEffect(() => {
    props.setLoaderLoading(true);
    let formData = util.getOrgProfileFormData(organizationData);
    form.setFields(formData);
    if (organizationData.length !== 0) {
      organizationData.organizationDescription &&
        setWordCount(
          organizationData.organizationDescription.trim().split(/\s+/).length,
        );
      OrganizationService.fetchLogo(organizationData.organizationId)
        .then((res) => {
          setLogo(res.data.data);
          props.setLoaderLoading(false);
        })
        .catch((err) => {
          props.setLoaderLoading(false);
        });
    }
  }, [organizationData]);

  return (
    <>
      {contextHolder}
      <div className="body-container-setup">
        <div className="op">
          <Form
            form={form}
            onFinish={saveOrgData}
            onFinishFailed={() => {
              const rootElement = document.querySelector("#root");
              window.scrollTo(0, rootElement.scrollHeight);
            }}>
            <Row gutter={[24, 24]}>
              <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                <div className="op-right-header">Organization Information</div>
                <div className="op-label">Organization Logo</div>
                <div>
                  <LogoField logo={logo} organizationData={organizationData} />
                </div>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={14}
                lg={14}
                xl={14}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                }}>
                <div className="op-warning-card-wrapper">
                  <div className="op-warning-card">
                    <div className="op-p">
                      The prefilled fields have all been imported from CRIO.
                      Should any changes need to be made, please update the
                      information accordingly.
                    </div>
                    <br />
                    <br />
                    <div className="op-p">
                      **The changes you make in CRIO Connect will not affect
                      your information in the CRIO site app.
                    </div>
                  </div>
                </div>
                <UniformLogoField confirm={confirm} logo={logo} />
              </Col>
            </Row>
            <OrganizationNameField organizationData={organizationData} />
            <DisplayNameField
              publicDisplayName={publicDisplayName}
              confirm={confirm}
            />

            <WebsiteField
              confirm={confirm}
              organizationWebsiteUrl={organizationWebsiteUrl}
            />

            <DescriptionField
              organizationDescription={organizationDescription}
              confirm={confirm}
              wordCount={wordCount}
              setWordCount={setWordCount}
            />

            <InquiryInformation />
          </Form>
        </div>
      </div>
      <div className="body-container-setup-footer">
        <div className="body-container-setup-footer-inner">
          <Form
            form={form}
            onFinish={saveOrgData}
            onFinishFailed={() => {
              let errList = [];
              form.getFieldsError().forEach((el) => {
                if (el.errors.length !== 0) {
                  errList.push(el);
                }
              });
              const rootElement = document.querySelector("#root");
              window.scrollTo(0, rootElement.scrollHeight);
            }}>
            <Form.Item>
              <Button type="primary" htmlType="submit" className="setup-button">
                Save and Continue <FontAwesomeIcon icon={faChevronRight} />
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
}

export default EditOrgProfile;
