import { createSlice } from "@reduxjs/toolkit";

export const sessionSlice = createSlice({
  name: "session",
  initialState: {},
  reducers: {
    saveSession: (state, action) => {
      return action.payload;
    },
  },
});

export const { saveSession } = sessionSlice.actions;
export default sessionSlice.reducer;
