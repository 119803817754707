import React, { useEffect, useState } from "react";
import { Divider, Input, Select, Space, Table } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { faSearch, faUserCircle } from "@fortawesome/pro-solid-svg-icons";
import moment from "moment";
import { dropdownData } from "../../constants/dumps";

/**
 * A table component that displays a list of Principal Investigator users and their profile pictures.
 * @param {{pis}} pis - An array of Principal Investigator user objects to display in the table.
 * @param {{setSearch}} setSearch - A function to set the search query for the table.
 * @param {{loading}} loading - A boolean indicating whether the table is currently loading data.
 * @returns A table component displaying the Principal Investigator users data.
 */
const SidePiDataTable = ({ pis, setSearch, loading }) => {
  const [pageSize, setPageSize] = useState("5");
  const [tableData, setTableData] = useState();
  // An array of objects representing columns in a table. Each object contains a title, dataIndex, and render function.
  let columns = [
    {
      title: "Name",
      render: (__, record) => {
        return (
          <div className="table_pi_name pop-reg-666">
            {record.profilePicture ? (
              <img src={record.profilePicture} alt="Site Logo" />
            ) : (
              <FontAwesomeIcon
                icon={faUserCircle}
                fontSize={40}
                style={{
                  marginRight: "10px",
                  color: "rgb(153, 153, 153)",
                  border: "2px solid #009ceb",
                  borderRadius: "50%",
                }}
              />
            )}
            {record.piName}
          </div>
        );
      },
    },
    {
      title: "Site",
      dataIndex: "siteName",
      render: (siteName) => <div className="pop-reg-666">{siteName}</div>,
    },
    {
      title: "Email",
      dataIndex: "email",
      render: (email) => <div className="pop-reg-666">{email}</div>,
    },
    {
      title: "Profile Status",
      render: (__, record) => {
        return (
          <div className="table-profile-status-wrapper">
            <div>
              <div className="table-profile-status pop-reg-666">
                <span
                  style={{ color: record.isActive ? "#009245" : "#C04040" }}
                >
                  {record.isActive ? "Public" : "Unpublished"}
                </span>{" "}
                {record.statusChangedDate ? "since" : ""}
              </div>
              <div className="table-profile-status-date pop-reg-666">
                {record.statusChangedDate
                  ? moment(record.statusChangedDate).format("DD-MMM-yyyy h:mmA")
                  : null}
              </div>
            </div>
            <div
              className="site-right-arrow"
              style={{ display: record.action ? "block" : "none" }}
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </div>
          </div>
        );
      },
    },
  ];
  columns = columns.map((el) => {
    el.onCell = (_, index) => ({
      width: 100 / columns.length + "%",
    });
    return el;
  });

  /**
   * Updates the search state with the value of the search input field.
   * @param {{Event}} e - The event object triggered by the search input field.
   */
  const onSearch = (e) => {
    setSearch(e.target.value);
  };

  // This function sets the table data to the value of the 'pis' variable whenever it changes.
  useEffect(() => {
    setTableData(pis);
  }, [pis]);

  /**
   * Renders the header of a table with a dropdown to select the number of entries to show,
   * a search bar to search for entries by name or site, and the column titles.
   * @returns A JSX element representing the table header.
   */
  const tableHeader = () => {
    return (
      <>
        <div className="title-container">
          <div className="table-header-wrapper">
            <div className="table-header-wrapper-title">
              <Space>
                <p>Show</p>
                <div className="table-header-wrapper-title-dropdown">
                  <Select
                    defaultValue="5"
                    onChange={(value) => {
                      setPageSize(value);
                    }}
                    options={dropdownData}
                    style={{ width: "100%" }}
                    className="crio-input"
                  />
                </div>
                <p>Entries</p>
              </Space>
            </div>
            <div className="table-header-wrapper-search">
              <Input
                placeholder="Search by Name, Site"
                size="large"
                onChange={onSearch}
                suffix={
                  <FontAwesomeIcon
                    icon={faSearch}
                    className="dashboard-search-icon"
                  />
                }
                style={{ width: "100%" }}
                className="crio-input"
              />
            </div>
          </div>
          <Divider type="horizontal" className="horizontal-divider-class" />
        </div>
        <div className="table-header-column">
          {columns.map((col) => {
            return (
              <div
                className="col-title"
                style={{ width: 100 / columns.length + "%" }}
                key={col.title}
              >
                {col.title}
              </div>
            );
          })}
        </div>
      </>
    );
  };

  return (
    <Table
      columns={columns}
      dataSource={tableData}
      title={tableHeader}
      className={"ant-side-table"}
      showHeader={false}
      loading={loading}
      pagination={{
        pageSize: pageSize,
      }}
      onRow={(record, rowIndex) => {
        return {
          style: { opacity: record.isActive ? "1" : "0.7" },
          onClick: (event) => {
            window.open(
              "/site/" + record.siteId + "/" + record.principalInvestigatorId,
              "_blank"
            );
          },
          onMouseEnter: (event) => {
            setTableData(
              tableData.map((el, index) => {
                if (rowIndex === index) {
                  el.action = "visible";
                  return el;
                }
                return el;
              })
            );
          },
          onMouseLeave: (event) => {
            setTableData(
              tableData.map((el, index) => {
                if (rowIndex === index) {
                  delete el.action;
                  return el;
                }
                return el;
              })
            );
          },
        };
      }}
    />
  );
};
export default SidePiDataTable;
