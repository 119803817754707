import { createSlice } from "@reduxjs/toolkit";

// A Redux slice that manages the state of the board certification information.
export const boardCertSlice = createSlice({
  name: "boardCert",
  initialState: [],
  reducers: {
    saveBoardCert: (state, action) => {
      return action.payload;
    },
    removeBoardCert: (state, action) => {
      return [];
    },
  },
});

export const { saveBoardCert, removeBoardCert } = boardCertSlice.actions;
export default boardCertSlice.reducer;
