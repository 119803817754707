import React from "react";
import { Row, Col, Space, Skeleton } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserDoctor,
  faBook,
  faFlag,
  faThumbTack,
  faCalendar,
  faCertificate,
} from "@fortawesome/pro-light-svg-icons";
import { faHashtag } from "@fortawesome/pro-solid-svg-icons";
import AboutSiteImageCarousel from "./AboutSiteImageCarousel";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

/**
 * Renders the About Sites component, which displays information about a clinical trial site and its principal investigator.
 * @param {{pi: object, site: object, siteImages: array}} props - The props object containing information about the site and its PI.
 * @returns The About Sites component, which displays information about a clinical trial site and its principal investigator.
 */
function AboutSites({ pi, site, siteImages }) {
  return (
    <>
      <div className="about-site-table">
        <Row span={24}>
          <Col xs={12} sm={12} md={12} lg={6} xl={6}>
            <Space className={"about-site-table-label"}>
              <div className="about-site-table-icon">
                <FontAwesomeIcon icon={faUserDoctor} />
              </div>
              Number of PI
            </Space>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={18}
            xl={18}
            className={"about-site-table-value"}
          >
            {site ? site.numOfPi : "NA"}
          </Col>
          <Col xs={12} sm={12} md={12} lg={6} xl={6}>
            <Space className={"about-site-table-label"}>
              <div className="about-site-table-icon">
                <FontAwesomeIcon icon={faHashtag} />
              </div>
              Number of Studies
            </Space>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={18}
            xl={18}
            className={"about-site-table-value"}
          >
            {site && site.numOfStudies ? site.numOfStudies : "NA"}
          </Col>
          <Col xs={12} sm={12} md={12} lg={6} xl={6}>
            <Space className={"about-site-table-label"}>
              <div className="about-site-table-icon">
                <FontAwesomeIcon icon={faBook} />
              </div>
              Therapeutic Areas
            </Space>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={18}
            xl={18}
            className={"about-site-table-value"}
          >
            {site && site.therapeuticList && site.therapeuticList.length !== 0
              ? site.therapeuticList.toString().replaceAll(",", ", ")
              : "NA"}
          </Col>
          <Col xs={12} sm={12} md={12} lg={6} xl={6}>
            <Space className={"about-site-table-label"}>
              <div className="about-site-table-icon">
                <FontAwesomeIcon icon={faFlag} />
              </div>
              Website URL
            </Space>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={18}
            xl={18}
            className={"about-site-table-value"}
          >
            {site && site.websiteUrl ? <a>{site.websiteUrl}</a> : "NA"}
          </Col>
          <Col xs={12} sm={12} md={12} lg={6} xl={6}>
            <Space className={"about-site-table-label"}>
              <div className="about-site-table-icon">
                <FontAwesomeIcon icon={faThumbTack} />
              </div>
              Location
            </Space>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={18}
            xl={18}
            className={"about-site-table-value"}
          >
            {site
              ? site.houseAddress +
                ", " +
                site.city +
                ", " +
                site.state +
                ", " +
                site.country
              : "NA"}
          </Col>
        </Row>
        <Row>
          {site && site.siteDesciption ? (
            <div
              dangerouslySetInnerHTML={{ __html: site.siteDesciption }}
              className={"about-site-desc"}
            />
          ) : null}
        </Row>
        <Row style={{ margin: "20px 0" }}>
          {siteImages && siteImages.length !== 0 ? (
            <AboutSiteImageCarousel siteImages={siteImages} />
          ) : null}
        </Row>
        <Row style={{ margin: "40px 0" }}>
          <Col span={24}>
            <span>Facility and Equipment</span>
          </Col>
          <Col span={24}>
            <Row gutter={24}>
              {site && site.facilityList
                ? site.facilityList.map((el) => {
                    return (
                      <Col
                        span={6}
                        key={el}
                        xs={12}
                        sm={12}
                        md={8}
                        lg={6}
                        xl={6}
                      >
                        <Space className={"about-site-table-value"}>
                          <div className="about-site-table-icon">
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="green"
                            />
                          </div>
                          {el}
                        </Space>
                      </Col>
                    );
                  })
                : null}
            </Row>
          </Col>
        </Row>
      </div>
      <div className="about-principal-table">
        <span>Principal Investigator</span>
        {pi ? (
          pi.length !== 0 && pi.piName ? (
            <>
              <Row>
                <div className="site-page-pi-profile-pic">
                  {pi.piLogo ? (
                    <img src={pi.piLogo} alt="PI Profile" />
                  ) : (
                    <Skeleton.Image />
                  )}
                </div>
              </Row>
              <Row>
                <Space className="about-principal-table-name">
                  <FontAwesomeIcon icon={faUserDoctor} />{" "}
                  <span>{pi.piName}</span>
                </Space>
              </Row>
              <Row>
                <div className="about-principal-table-name-desc">
                  Principal Investigator at {site.siteName}
                </div>
              </Row>
              <Row>
                <div className="about-site-table">
                  <span>About the PI</span>
                </div>
              </Row>
              <Row span={24}>
                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                  <Space className={"about-site-table-label"}>
                    <div className="about-site-table-icon">
                      <FontAwesomeIcon icon={faCalendar} />
                    </div>
                    Years of Experience
                  </Space>
                </Col>
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={18}
                  xl={18}
                  className={"about-site-table-value"}
                >
                  {pi.yearExperience || pi.yearExperience === 0
                    ? pi.yearExperience < 2
                      ? pi.yearExperience + " year"
                      : pi.yearExperience + " years"
                    : "NA"}
                </Col>
                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                  <Space className={"about-site-table-label"}>
                    <div className="about-site-table-icon">
                      <FontAwesomeIcon icon={faHashtag} />
                    </div>
                    Number of studies.
                  </Space>
                </Col>
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={18}
                  xl={18}
                  className={"about-site-table-value"}
                >
                  {pi.numOfStudies ? pi.numOfStudies : "NA"}
                </Col>
                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                  <Space className={"about-site-table-label"}>
                    <div className="about-site-table-icon">
                      <FontAwesomeIcon icon={faFlag} />
                    </div>
                    Website URL
                  </Space>
                </Col>
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={18}
                  xl={18}
                  className={"about-site-table-value"}
                >
                  {pi.websiteUrl ? <a>{pi.websiteUrl}</a> : "NA"}
                </Col>
                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                  <Space className={"about-site-table-label"}>
                    <div className="about-site-table-icon">
                      <FontAwesomeIcon icon={faBook} />
                    </div>
                    Therapeutic Areas
                  </Space>
                </Col>
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={18}
                  xl={18}
                  className={"about-site-table-value"}
                >
                  {pi.therapeutic && pi.therapeutic.length !== 0
                    ? pi.therapeutic.toString().replaceAll(",", ", ")
                    : "NA"}
                </Col>
                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                  <Space className={"about-site-table-label"}>
                    <div className="about-site-table-icon">
                      <FontAwesomeIcon icon={faCertificate} />
                    </div>
                    Professional Certifications
                  </Space>
                </Col>
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={18}
                  xl={18}
                  className={"about-site-table-value"}
                >
                  {pi.boardCerti && pi.boardCerti.length !== 0
                    ? pi.boardCerti.toString().replaceAll(",", ", ")
                    : "NA"}
                </Col>
              </Row>
              <Row>
                {pi.piDescription ? (
                  <div
                    dangerouslySetInnerHTML={{ __html: pi.piDescription }}
                    className={"about-site-desc"}
                  />
                ) : null}
              </Row>
            </>
          ) : (
            <Skeleton active />
          )
        ) : (
          "No Principal Investigator Available"
        )}
      </div>
    </>
  );
}

export default AboutSites;
