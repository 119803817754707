import { Spin } from "antd";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Stepper from "../../components/Stepper";
import OrganizationService from "../../services/organizationService";
import { saveAreas } from "../../features/areas";
import { saveBoardCert } from "../../features/boardCert";
import { saveNoOfStudies } from "../../features/noOfStudies";

/**
 * Renders the organization profile setup page, which includes a stepper component
 * that guides the user through the process of setting up their organization profile.
 * @returns - The organization profile setup page.
 */
const OrgProfileSetup = () => {
  const dispatch = useDispatch();
  const adminData = useSelector((state) => state.admin);
  let organizationData = useSelector((state) => state.organization);
  const [step, setStep] = useState(0);

  /**
   * useEffect hook that runs when the component mounts and when the 'step' state changes.
   * It scrolls the window to the top of the page, checks if the adminData and organizationData
   * arrays are empty and redirects to the home page if they are. It also sets the 'step' state
   * based on the 'organizationData' object. Finally, it makes API calls to get all areas, board
   * certifications, and number of studies and saves them to the Redux store.
   */
  useEffect(() => {
    window.scrollTo(0, 0);
    if (adminData.length === 0 || organizationData === 0) {
      window.location.href = "/home";
    }
    if (organizationData.stepOneCompleted === null) {
      setStep(0);
    } else if (organizationData.stepTwoCompleted === null) {
      setStep(1);
    }
    OrganizationService.getAllAreas().then((res) => {
      dispatch(saveAreas(res.data.data));
    });
    OrganizationService.getAllBoardCert().then((res) => {
      dispatch(saveBoardCert(res.data.data));
    });
    OrganizationService.getAllNoOfStudies().then((res) => {
      dispatch(saveNoOfStudies(res.data.data));
    });
  }, [step]);
  return adminData && organizationData ? (
    <>
      <Stepper step={step} organizationData={organizationData} />
    </>
  ) : (
    <Spin />
  );
};

export default OrgProfileSetup;
