import { configureStore } from "@reduxjs/toolkit";
import adminReducer from "../features/admin";
import organizationReducer from "../features/organization";
import sitesReducer from "../features/sites";
import statesReducer from "../features/states";
import facilityReducer from "../features/facility";
import areasReducer from "../features/areas";
import boardCertReducer from "../features/boardCert";
import noOfStudiesReducer from "../features/noOfStudies";
import dirtyFormReducer from "../features/dirtyForm";
import sessionReducer from "js/features/sessionSlice";

// Configures the Redux store with the given reducers.
const Store = configureStore({
  reducer: {
    admin: adminReducer,
    organization: organizationReducer,
    sites: sitesReducer,
    facility: facilityReducer,
    states: statesReducer,
    areas: areasReducer,
    boardCert: boardCertReducer,
    noOfStudies: noOfStudiesReducer,
    dirtyForm: dirtyFormReducer,
    session: sessionReducer,
  },
});

export default Store;
