import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import PublicUnpublishedFilterTable from "../../components/PublicUnpublishedFilterTable";
import SidePiDataTable from "../../components/SidePiDataTable";
import OrganizationService from "../../services/organizationService";

/**
 * Renders a component that displays a list of principal investigators and allows the user to filter
 * the list by publication status and search for specific investigators.
 */
const PrincipalInvestigator = () => {
  const [piData, setPiData] = useState([]);
  const [loading, setLoading] = useState([]);
  const [piCount, setPiCount] = useState(piData.length);
  const [publicCheck, setPublicCheck] = useState(false);
  const [unpublishedCheck, setUnpublishedCheck] = useState(false);
  const [search, setSearch] = useState();
  const [pis, setPis] = useState();

  /**
   * Updates the search data and calls the onChangeFilter function with the updated data.
   * @param {{Object}} searchData - The updated search data.
   */
  const changeSearch = (searchData) => {
    setSearch(searchData);
    onChangeFilter(publicCheck, unpublishedCheck, piData, searchData);
  };

  /**
   * Filters the given data based on the search criteria and sets the filtered data to the state.
   * @param {{searchData}} searchData - The search criteria to filter the data.
   * @param {{data}} data - The data to be filtered.
   */
  const searchFilter = (searchData, data) => {
    let tempPis = [];
    if (!searchData || searchData === "") {
      setPis(data);
    } else {
      data.forEach((pi) => {
        if (
          pi.piName.toLowerCase().includes(searchData.toLowerCase()) ||
          pi.siteName.toLowerCase().includes(searchData.toLowerCase())
        ) {
          tempPis.push(pi);
        }
      });
      setPis(tempPis);
    }
  };

  /**
   * Clears the current selection by resetting the public and unpublished check states to false
   * and updating the search filter with the current search term and piData.
   */
  const clearSelection = () => {
    setPublicCheck(false);
    setUnpublishedCheck(false);
    searchFilter(search, piData);
  };

  /**
   * Updates the state of the public check and calls the onChangeFilter function with the updated data.
   * @param {{any}} data - The new data to set the public check to.
   */
  const changePublic = (data) => {
    setPublicCheck(data);
    onChangeFilter(data, unpublishedCheck, piData, search);
  };

  /**
   * Updates the unpublished check state and calls the onChangeFilter function with the updated state.
   * @param {{any}} data - The new data to set the unpublished check state to.
   */
  const changeUnpublished = (data) => {
    setUnpublishedCheck(data);
    onChangeFilter(publicCheck, data, piData, search);
  };

  /**
   * Filters the given data based on the provided parameters and calls the searchFilter function
   * with the filtered data and the searchData.
   * @param {{boolean}} pc - A boolean indicating whether to include published content.
   * @param {{boolean}} unPublished - A boolean indicating whether to include unpublished content.
   * @param {{Array}} data - An array of data to filter.
   * @param {{Object}} searchData - An object containing search data.
   */
  const onChangeFilter = (pc, unPublished, data, searchData) => {
    let tempPis = [];
    data.forEach((pi) => {
      if (pc && pi.isActive) {
        tempPis.push(pi);
      } else if (unPublished && !pi.isActive) {
        tempPis.push(pi);
      } else if ((!unPublished && !pc) || (unPublished && pc)) {
        tempPis.push(pi);
      }
    });
    searchFilter(searchData, tempPis);
  };

  // useEffect hook that fetches all organization PIs and updates the state with the data.
  useEffect(() => {
    setLoading(true);
    OrganizationService.getAllOrgPis()
      .then((res) => {
        setPiData(res.data.data.orgPiDatas);
        setPiCount(res.data.data.totalNumOfPI);
        setLoading(false);
        onChangeFilter(
          publicCheck,
          unpublishedCheck,
          res.data.data.orgPiDatas,
          search
        );
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);
  return (
    <div className="body-container" style={{ minHeight: "80vh" }}>
      <div className="sites-header">
        Principal Investigator<p>{piCount}</p>
      </div>
      <Row gutter={[24, 24]}>
        <Col xs={24} sm={24} md={5} lg={5} xl={5}>
          <div className="sites-left">
            <PublicUnpublishedFilterTable
              publicCheck={publicCheck}
              setPublicCheck={changePublic}
              unpublishedCheck={unpublishedCheck}
              setUnpublishedCheck={changeUnpublished}
              clearSelection={clearSelection}
            />
          </div>
        </Col>
        <Col xs={24} sm={24} md={19} lg={19} xl={19}>
          <div className="sites-left">
            <SidePiDataTable
              pis={pis}
              setSearch={changeSearch}
              loading={loading}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default PrincipalInvestigator;
