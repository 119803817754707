import { createSlice } from "@reduxjs/toolkit";

// A Redux slice that manages the state of the sites in the application.
export const sitesSlice = createSlice({
  name: "sites",
  initialState: [],
  reducers: {
    saveAllSites: (state, action) => {
      return action.payload;
    },
    removeAllSites: (state, action) => {
      return [];
    },
    updateSite: (state, action) => {
      return state.map((site) => {
        if (action.payload.siteId === site.siteId) {
          return action.payload;
        }
        return site;
      });
    },
  },
});

export const { saveAllSites, removeAllSites, updateSite } = sitesSlice.actions;
export default sitesSlice.reducer;
