import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  faCaretRight,
  faExclamationCircle,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Checkbox,
  Collapse,
  Modal,
  notification,
  Skeleton,
  Space,
  Switch,
} from "antd";
import OrganizationService from "../services/organizationService";
import SinglePIProfileEdit from "./SinglePIProfileEdit";
import LoadingModal from "./LoadingModal";
import SitePageService from "../services/SitePageService";
import { updateSite } from "../features/sites";
import { saveDirtyForm } from "../features/dirtyForm";
const { Panel } = Collapse;

/**
 * A component that displays the list of principal investigators for a site and allows the user to edit their profiles.
 * @param {{Object}} siteData - The data for the site being edited.
 * @param {Function} setActiveTab - A function to set the active tab.
 * @param {Function} setEdited - A function to set whether the site has been edited.
 * @param {Boolean} showWarning - A boolean indicating whether to show a warning message.
 * @param {Function} setShowWarning - A function to set whether to show a warning message.
 * @param {Function} exitTab - A function to exit the current tab.
 * @param {Function} upcomingTab - A function to go to the upcoming tab
 */
function PrincipalInvestigatorProfileEdit({
  siteData,
  setActiveTab,
  setEdited,
  showWarning,
  setShowWarning,
  exitTab,
  upcomingTab,
  edited,
  setUpcomingTab,
  pId,
}) {
  const [pis, setPis] = useState([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingSwitch, setLoadingSwitch] = useState(false);
  const [hasNoPi, setHasNoPi] = useState(siteData.hasNoPi);
  const [activeKey, setActiveKey] = useState(-1);
  const dispatch = useDispatch();
  const [modal, contextHolder] = Modal.useModal();
  const confirm = (pi) => {
    if (edited) {
      modal.confirm({
        title: (
          <div className="warning-right-header">
            {activeKey === pi.principalInvestigatorId
              ? "Close Without Saving ?"
              : "Open another tab ?"}
          </div>
        ),
        icon: (
          <FontAwesomeIcon
            icon={faExclamationCircle}
            color="#009ceb"
            fontSize={50}
          />
        ),
        content: (
          <div className="warning-right-body">
            {activeKey === pi.principalInvestigatorId
              ? "Closing this tab without saving will abandon any unsaved edits"
              : "Opening another tab will close previous tab without saving which will abandon any unsaved edits"}
          </div>
        ),
        okButtonProps: {
          className: "warning-footer-right",
          style: { background: "#fff" },
        },
        cancelButtonProps: { className: "warning-footer-left" },
        okText: "Close Without Saving",
        cancelText: "Cancel",
        onOk: () => {
          if (activeKey === pi.principalInvestigatorId) {
            setEdited(false);
            dispatch(saveDirtyForm(false));
            setActiveKey(-1);
          } else {
            setActiveKey(pi.principalInvestigatorId);
            setEdited(false);
            dispatch(saveDirtyForm(false));
          }
        },
        centered: true,
        width: 500,
      });
    } else {
      if (activeKey === pi.principalInvestigatorId) {
        setEdited(false);
        dispatch(saveDirtyForm(false));
        setActiveKey(-1);
      } else {
        setActiveKey(pi.principalInvestigatorId);
        setEdited(false);
        dispatch(saveDirtyForm(false));
      }
    }
  };

  // Changes the status of a principal investigator and updates the site information.
  const changePiStatus = (pi) => {
    if (pi.profilePercentage < 100 && pi.isActive === 0) {
      setActiveKey(pi.principalInvestigatorId);
    }
    if (pi.profilePercentage === 100 || pi.isActive === 1) {
      setLoadingSwitch(true);
      SitePageService.switchStatusForPI(pi.principalInvestigatorId)
        .then((res) => {
          let tempPis = pis.map((el) => {
            if (pi.principalInvestigatorId === el.principalInvestigatorId) {
              let temp = Object.assign({}, el);
              temp.isActive = res.data.data.isActive;
              return temp;
            }
            return el;
          });
          SitePageService.getSiteInfoForPer(siteData.siteId)
            .then((res2) => {
              let temp = Object.assign({}, siteData);
              temp.percentageComplete = res2.data.data.percentageComplete;
              temp.isActive = res2.data.data.isActive;
              temp.hasNoPi = res2.data.data.hasNoPi;
              dispatch(updateSite(temp));
              setEdited(false);
              setActiveKey(-1);
              setLoadingSwitch(false);
            })
            .catch((err) => {
              notification.error({
                message: "Something went wrong ! Please refresh the page...",
              });
              setLoadingSwitch(false);
            });
          setPis(tempPis);
          notification.success({
            message:
              pi.piName +
              " successfully " +
              (res.data.data.isActive ? "published !!" : "unpublished !!"),
          });
        })
        .catch(() => {
          notification.error({
            message:
              "Error changing principal investigators status !!! Please try again...",
          });
          setLoadingSwitch(false);
        });
    } else {
      notification.error({
        message: `Please save all mandatory fields to publish ${pi.piName}`,
      });
    }
  };

  // Sets the no Pi option for the current site and updates the site data accordingly.
  const setNoPi = (value) => {
    setLoadingSwitch(true);
    let payload = {
      hasNoPi: value.target.checked ? 1 : 0,
    };
    OrganizationService.setNoPiForSite(siteData.siteId, payload)
      .then((res) => {
        setHasNoPi(value.target.checked);
        SitePageService.getSiteInfoForPer(siteData.siteId)
          .then((res2) => {
            let temp = Object.assign({}, siteData);
            temp.percentageComplete = res2.data.data.percentageComplete;
            temp.isActive = res2.data.data.isActive;
            temp.hasNoPi = res2.data.data.hasNoPi;
            dispatch(updateSite(temp));
            setActiveKey(-1);
            setLoadingSwitch(false);
          })
          .catch((err) => {
            notification.error({
              message: "Something went wrong ! Please refresh the page...",
            });
            setLoadingSwitch(false);
          });
      })
      .catch(() => {
        notification.error({
          message: "Unable to change PI setting!!! Please try again later...",
        });
        setLoadingSwitch(false);
      });
  };

  useEffect(() => {
    if (pId !== -1) {
      setActiveKey(pId);
    }
  }, [pId]);
  // useEffect hook that sets the loading state to true and retrieves the PI data for the given site.
  useEffect(() => {
    setLoading(true);
    if (siteData) {
      setHasNoPi(siteData.hasNoPi);
      OrganizationService.getSitePi(siteData.siteId)
        .then((res) => {
          setPis(res.data.data);
          setCount(res.data.data.length);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, []);
  return (
    <>
      {contextHolder}
      <LoadingModal open={loadingSwitch} />
      <div className="PI-edit-header">
        <div className="pi-list-header">
          <div className="sites-header pi-header">
            Site Principal Investigator<p>{count}</p>
          </div>
        </div>
        <div className="pi-header-check">
          <Checkbox
            className="op-checkbox"
            checked={hasNoPi}
            onChange={setNoPi}>
            No Principal Investigator
          </Checkbox>
        </div>
      </div>
      <div className="pi-edit-body">
        {loading ? (
          <Skeleton active />
        ) : (
          pis.map((pi, index) => {
            let percentageComplete = pi.profilePercentage
              ? pi.profilePercentage
              : 0;
            return (
              <Collapse
                collapsible={hasNoPi ? "disabled" : "icon"}
                key={pi.principalInvestigatorId}
                ghost
                expandIconPosition="end"
                expandIcon={({ isActive }) => (
                  <FontAwesomeIcon
                    icon={faCaretRight}
                    rotation={isActive ? 90 : 0}
                    className="site-expand-icon"
                  />
                )}
                destroyInactivePanel
                activeKey={activeKey}
                onChange={() => {
                  confirm(pi);
                }}>
                <Panel
                  key={pi.principalInvestigatorId}
                  header={
                    <div
                      className="site-header-bar"
                      style={{ opacity: hasNoPi ? "0.6" : "1" }}>
                      <Space size={24}>
                        <Space size={10}>
                          <div
                            className="site-header-circle"
                            style={{
                              backgroundColor: pi.isActive
                                ? "#13b639"
                                : "#999999",
                            }}
                            onClick={() => {
                              confirm(pi);
                            }}
                          />
                          <Switch
                            checkedChildren="ON"
                            unCheckedChildren="OFF"
                            checked={pi.isActive ? true : false}
                            onChange={() => changePiStatus(pi)}
                            disabled={hasNoPi}
                          />
                        </Space>
                        <div
                          className="pi-header-name"
                          onClick={() => {
                            confirm(pi);
                          }}>
                          {pi.piName}
                        </div>
                      </Space>
                      <div
                        className="site-progress-bar"
                        onClick={() => {
                          confirm(pi);
                        }}>
                        <div className="bar">
                          <div
                            className="bar-fill"
                            style={{
                              backgroundColor: pi.isActive
                                ? percentageComplete > 69
                                  ? "#13b639"
                                  : percentageComplete > 49
                                    ? "#e3761c"
                                    : "red"
                                : "#cccccc",
                              width: `${percentageComplete}%`,
                            }}
                          />
                        </div>
                        <div className="bar-text">
                          {percentageComplete}% Complete
                        </div>
                      </div>
                    </div>
                  }>
                  <SinglePIProfileEdit
                    piData={pi}
                    pis={pis}
                    setPis={setPis}
                    siteData={siteData}
                    setActiveTab={setActiveTab}
                    last={pis.length === index + 1}
                    setActiveKey={setActiveKey}
                    setEdited={setEdited}
                    showWarning={showWarning}
                    setShowWarning={setShowWarning}
                    exitTab={exitTab}
                    upcomingTab={upcomingTab}
                    edited={edited}
                    setUpcomingTab={setUpcomingTab}
                  />
                </Panel>
              </Collapse>
            );
          })
        )}
      </div>
    </>
  );
}

export default PrincipalInvestigatorProfileEdit;
