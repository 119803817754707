import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Collapse,
  Form,
  Input,
  notification,
  Skeleton,
  Space,
  Switch,
} from "antd";
import {
  faCaretRight,
  faChevronRight,
  faSearch,
} from "@fortawesome/pro-solid-svg-icons";
import { useSelector, useDispatch } from "react-redux";
import OrganizationService from "../services/organizationService";
import { saveAllSites, updateSite } from "../features/sites";
import { saveFacility } from "../features/facility";
import { saveStates } from "../features/states";
import SiteEditTab from "./SiteEditTab";
import SitePageService from "../services/SitePageService";
const { Panel } = Collapse;

// A component that displays a list of sites and allows the user to edit their information.
function EditSiteProfile(props) {
  const dispatch = useDispatch();
  const sites = useSelector((state) => state.sites);
  const [siteCount, setSiteCount] = useState(sites.length);
  const [loading, setLoading] = useState(false);
  const [siteActiveKey, setSiteActiveKey] = useState(-1);
  const [pageNumber, setPageNumber] = useState(2);
  const [searchText, setSearchText] = useState("");
  const [moreLoading, setMoreLoading] = useState(false);
  const orgData = useSelector((state) => state.organization);
  const [gotoLoader, setGotoLoader] = useState(false);
  const pageSize = 12;

  // Saves the organization data to the server and redirects the user to the dashboard page.
  const saveOrgData = () => {
    setGotoLoader(true);
    let payload = Object.assign({}, orgData);
    payload.stepTwoCompleted = new Date();
    OrganizationService.saveOrganizationDetails(payload).then((res) => {
      setGotoLoader(false);
      window.location.href = "/dashboard";
    });
  };

  // Changes the status of a site to either published or unpublished based on the current status.
  const changeSiteStatus = (site) => {
    if (site.percentageComplete === 100) {
      props.setLoaderLoading(true);
      SitePageService.switchStatusForSite(site.siteId).then((res) => {
        SitePageService.getSiteInfoForPer(site.siteId)
          .then((res2) => {
            let temp = Object.assign({}, site);
            temp.percentageComplete = res2.data.data.percentageComplete;
            temp.isActive = res2.data.data.isActive;
            dispatch(updateSite(temp));
            notification.success({
              message: `${temp.siteName} ${
                temp.isActive ? "published" : "unpublished"
              } successfully...`,
            });
            props.setLoaderLoading(false);
          })
          .catch((err) => {
            notification.error({
              message: "Something went wrong ! Please refresh the page...",
            });
            props.setLoaderLoading(false);
          });
      });
    } else {
      notification.error({
        message: "Please save all mandatory fields to publish " + site.siteName,
      });
    }
  };

  /**
   * Searches for sites based on the given parameters and sets the state accordingly.
   * @param {{number}} pSize - The number of sites to return per page.
   * @param {{number}} pNumber - The page number to return.
   * @param {{string}} sText - The text to search for in the site names.
   */
  const searchSites = (pSize, pNumber, sText) => {
    setLoading(true);
    props.setLoaderLoading(true);
    OrganizationService.getAllSites(pSize, pNumber, sText)
      .then((res) => {
        dispatch(saveAllSites(res.data.data.siteResponseDtos));
        setSiteCount(res.data.data.siteCount);
        props.setLoaderLoading(false);
        setPageNumber(2);
        setLoading(false);
      })
      .catch(() => {
        props.setLoaderLoading(false);
        setLoading(false);
      });
  };

  // Loads more sites from the server and appends them to the existing list of sites.
  const loadMore = () => {
    setMoreLoading(true);
    OrganizationService.getAllSites(pageSize, pageNumber, searchText).then(
      (res) => {
        dispatch(saveAllSites([...sites, ...res.data.data.siteResponseDtos]));
        setSiteCount(res.data.data.siteCount);
        setMoreLoading(false);
        setPageNumber(pageNumber + 1);
      },
    );
  };

  /**
   * useEffect hook that fetches data from the server and updates the state of the component.
   * @param {{function}} props.setLoaderLoading - function to set the loading state of the loader component.
   * @param {{function}} dispatch - function to dispatch an action to the store.
   * @param {{function}} dispatch(saveAllSites) - action to save all sites to the store.
   * @param {{function}} dispatch(saveStates) - action to save all states to the store.
   * @param {{function}} dispatch(saveFacility) - action to save all facilities to the store.
   */
  useEffect(() => {
    window.scrollTo(0, 0);
    props.setLoaderLoading(true);
    setLoading(true);
    OrganizationService.getAllSites(24, 0, searchText)
      .then((res) => {
        dispatch(saveAllSites(res.data.data.siteResponseDtos));
        setSiteCount(res.data.data.siteCount);
        props.setLoaderLoading(false);
        setPageNumber(2);
        setLoading(false);
      })
      .catch((error) => {
        props.setLoaderLoading(false);
        setLoading(false);
      });
    OrganizationService.getStates().then((res) => {
      dispatch(saveStates(res.data.data));
    });
    OrganizationService.getFacility().then((res) => {
      dispatch(saveFacility(res.data.data));
    });
  }, []);

  return (
    <>
      <div className="body-container-setup">
        <div className="site-setup">
          <div className="site-setup-header">
            <p className="op-p-i">
              -All fields are required unless stated as optional
            </p>
            <p className="op-p-i">
              -Updating any unified fields on a site will only affect that
              specific site
            </p>

            <div className="sites-header-wrapper">
              <div className="sites-header">
                Site Information<p>{siteCount}</p>
              </div>
              <div className="search-site-setup">
                <Input
                  className="crio-input dashboard-search"
                  placeholder="Search by Site"
                  suffix={
                    <FontAwesomeIcon
                      icon={faSearch}
                      className="dashboard-search-icon"
                      onClick={() => {
                        searchSites(24, 0, searchText);
                      }}
                    />
                  }
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                  onPressEnter={() => {
                    searchSites(24, 0, searchText);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="site-setup-body">
            {loading ? (
              <Skeleton active />
            ) : (
              sites.map((site) => {
                let percentageComplete = site.percentageComplete
                  ? site.percentageComplete
                  : 0;
                return (
                  <Collapse
                    collapsible="icon"
                    key={site.siteId}
                    ghost
                    expandIconPosition="end"
                    expandIcon={({ isActive }) => (
                      <FontAwesomeIcon
                        icon={faCaretRight}
                        rotation={isActive ? 90 : 0}
                        className="site-expand-icon"
                      />
                    )}
                    destroyInactivePanel
                    activeKey={siteActiveKey}
                    onChange={() => {
                      if (siteActiveKey === site.siteId) {
                        setSiteActiveKey(-1);
                      } else {
                        setSiteActiveKey(site.siteId);
                      }
                    }}>
                    <Panel
                      key={site.siteId}
                      header={
                        <div className="site-header-bar">
                          <Space size={24}>
                            <Space size={10}>
                              <div
                                className="site-header-circle"
                                style={{
                                  backgroundColor: site.isActive
                                    ? "#13b639"
                                    : "#999999",
                                }}
                                onClick={() => {
                                  if (siteActiveKey === site.siteId) {
                                    setSiteActiveKey(-1);
                                  } else {
                                    setSiteActiveKey(site.siteId);
                                  }
                                }}
                              />
                              <Switch
                                checkedChildren="ON"
                                unCheckedChildren="OFF"
                                checked={site.isActive ? true : false}
                                onChange={() => changeSiteStatus(site)}
                              />
                            </Space>
                            <div
                              className="site-header-name"
                              onClick={() => {
                                if (siteActiveKey === site.siteId) {
                                  setSiteActiveKey(-1);
                                } else {
                                  setSiteActiveKey(site.siteId);
                                }
                              }}>
                              {site.siteName}
                            </div>
                          </Space>
                          <div
                            className="site-progress-bar"
                            onClick={() => {
                              if (siteActiveKey === site.siteId) {
                                setSiteActiveKey(-1);
                              } else {
                                setSiteActiveKey(site.siteId);
                              }
                            }}>
                            <div className="bar">
                              <div
                                className="bar-fill"
                                style={{
                                  backgroundColor: site.isActive
                                    ? percentageComplete > 69
                                      ? "#13b639"
                                      : percentageComplete > 49
                                        ? "#e3761c"
                                        : "red"
                                    : "#cccccc",
                                  width: `${percentageComplete}%`,
                                }}
                              />
                            </div>
                            <div className="bar-text">
                              {percentageComplete}% Complete
                            </div>
                          </div>
                        </div>
                      }>
                      <SiteEditTab
                        siteData={site}
                        setSiteActiveKey={setSiteActiveKey}
                      />
                    </Panel>
                  </Collapse>
                );
              })
            )}
            {moreLoading ? <Skeleton active /> : null}
            {sites.length > 0 ? (
              <p className="site-setup-body-text-i">
                By switching this toggle switch, this site can be set to public
                (on) or unpublished (off)
              </p>
            ) : null}
          </div>
          {siteCount - sites.length > 0 && !moreLoading ? (
            <div className="hasmore-button site-edit-page-load-more">
              <span onClick={loadMore}>
                Load More <p>{siteCount - sites.length}</p>
              </span>
            </div>
          ) : null}
        </div>
      </div>
      <div className="body-container-setup-footer">
        <div className="body-container-setup-footer-inner">
          <Form onFinish={saveOrgData}>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="setup-button"
                loading={gotoLoader}>
                Go to Dashboard <FontAwesomeIcon icon={faChevronRight} />
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
}

export default EditSiteProfile;
