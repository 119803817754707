import { Checkbox, Divider } from "antd";
import React from "react";

/**
 * A component that displays a table with two checkboxes for filtering sites by their status.
 * @param {{boolean}} publicCheck - A boolean indicating whether the "Public" checkbox is checked.
 * @param {{function}} setPublicCheck - A function to set the value of the "Public" checkbox.
 * @param {{boolean}} unpublishedCheck - A boolean indicating whether the "Unpublished" checkbox is checked.
 * @param {{function}} setUnpublishedCheck - A function to set the value of the "Unpublished" checkbox.
 * @param {{function}} clearSelection - A function to clear the selection of the checkboxes.
 */
const PublicUnpublishedFilterTable = ({
  publicCheck,
  setPublicCheck,
  unpublishedCheck,
  setUnpublishedCheck,
  clearSelection,
}) => {
  return (
    <>
      <div className="sites-left-header">
        Status
        <p onClick={clearSelection}>clear</p>
      </div>
      <div className="sites-left-body">
        <Checkbox
          className={
            "sites-checkbox " + (publicCheck ? "sites-checkbox-checked" : "")
          }
          checked={publicCheck}
          onChange={(e) => {
            setPublicCheck(e.target.checked);
          }}
        >
          Public
        </Checkbox>
        <Divider type="horizontal" className="horizontal-divider-class" />
        <Checkbox
          className={
            "sites-checkbox " +
            (unpublishedCheck ? "sites-checkbox-checked" : "")
          }
          checked={unpublishedCheck}
          onChange={(e) => {
            setUnpublishedCheck(e.target.checked);
          }}
        >
          Unpublished
        </Checkbox>
        <Divider type="horizontal" className="horizontal-divider-class" />
      </div>
    </>
  );
};

export default PublicUnpublishedFilterTable;
