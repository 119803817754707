import axios from "config/axiosConfig";
import { commonUrl } from "constants/urls";

const SessionService = {
  getSessionData: () => {
    return axios.get(commonUrl + "session");
  },
};

export default SessionService;
