import axios from "../../config/axiosConfig";
import {
  acceptTermsAndConditionUrl,
  commonUrl,
  editSiteUrl,
  organizationAdminUrl,
  organizationUrl,
  pIEdit,
  studyUrl,
} from "../../constants/urls";
import util from "../../utils/util";

const OrganizationService = {
  /**
   * Retrieves the details of an organization with the given ID from the server.
   * @param {string} orgId - The ID of the organization to retrieve.
   * @returns {Promise} A promise that resolves with the details of the organization.
   */
  getOrganizationDetails: (orgId) => {
    return axios.get(organizationUrl + orgId);
  },
  /**
   * Retrieves the details of an organization admin with the given ID.
   * @param {string} adminId - The ID of the organization admin to retrieve details for.
   * @returns {Promise} A promise that resolves with the details of the organization admin.
   */
  getOrganizationAdminDetails: (adminId) => {
    return axios.get(organizationAdminUrl + adminId);
  },
  /**
   * Sends a PUT request to the server to accept the terms and conditions for the given organization admin ID.
   * @param {string} adminId - The ID of the organization admin who is accepting the terms and conditions.
   * @returns {Promise} A promise that resolves with the response from the server.
   */
  acceptTermsAndCondition: (adminId) => {
    let payload = {
      organizationAdminId: adminId,
      termsAndConditionChecked: true,
    };
    return axios.put(acceptTermsAndConditionUrl, payload);
  },
  /**
   * Sends a POST request to the organization URL with the given payload to save the organization details.
   * @param {Object} payload - The data to be sent in the request body.
   * @returns {Promise} A promise that resolves with the response data if the request is successful, or rejects with an error if the request fails.
   */
  saveOrganizationDetails: (payload) => {
    return axios.post(organizationUrl, payload);
  },
  /**
   * Uploads a logo to the organization's account.
   * @param {FormData} fmData - The form data containing the logo to upload.
   * @returns {Promise} A promise that resolves with the response from the server.
   */
  uploadLogo: (fmData) => {
    let config = {
      headers: { "content-type": "multipart/form-data" },
    };
    let orgId = util.getOrganizationId();
    return axios.put(organizationUrl + orgId + "/logo", fmData, config);
  },
  /**
   * Retrieves a list of all sites for the current organization, with optional pagination and search parameters.
   * @param {number} pageSize - The number of sites to retrieve per page.
   * @param {number} pageNumber - The page number to retrieve.
   * @param {string} searchText - The text to search for in site names.
   * @returns {Promise} A promise that resolves with the list of sites.
   */
  getAllSites: (pageSize, pageNumber, searchText) => {
    let orgId = util.getOrganizationId();
    var config = {
      method: "get",
      url: organizationUrl + orgId + "/sites",
      params: {
        pageSize: pageSize,
        pageNumber: pageNumber,
        searchText: searchText,
      },
      headers: {
        "Content-Type": "application/json",
      },
    };
    return axios(config);
  },
  /**
   * Sends a PUT request to the server to update the site with the given siteId.
   * @param {Object} payload - The payload object containing the siteId and the updated site data.
   * @returns A Promise that resolves with the updated site data.
   */
  updateSite: (payload) => {
    return axios.put(editSiteUrl + payload.siteId, payload);
  },
  /**
   * Fetches the logo for the organization with the given ID.
   * @param {string} orgId - The ID of the organization to fetch the logo for.
   * @returns {Promise} A promise that resolves with the logo data.
   */
  fetchLogo: (orgId) => {
    return axios.post(organizationUrl + orgId + "/logo");
  },
  /**
   * Uploads a site logo to the server for the given site ID.
   * @param {string} siteId - The ID of the site to upload the logo for.
   * @param {FormData} fmData - The form data containing the logo file to upload.
   * @returns A promise that resolves with the response from the server.
   */
  uploadSiteLogo: (siteId, fmData) => {
    let config = {
      headers: { "content-type": "multipart/form-data" },
    };
    return axios.put(editSiteUrl + siteId + "/logo", fmData, config);
  },
  /**
   * Fetches the logo for a given site ID using an Axios POST request to the edit site URL.
   * @param {string} siteId - The ID of the site to fetch the logo for.
   * @returns {Promise} A promise that resolves with the response data from the server.
   */
  fetchSiteLogo: (siteId) => {
    return axios.post(editSiteUrl + siteId + "/logo");
  },
  /**
   * Saves images for a given site ID using the provided form data.
   * @param {string} siteId - The ID of the site to save the image for.
   * @param {FormData} fmData - The form data containing the image to save.
   * @returns A promise that resolves with the response from the server.
   */
  saveSiteImage: (siteId, fmData) => {
    let config = {
      headers: { "content-type": "multipart/form-data" },
    };
    return axios.put(editSiteUrl + siteId + "/images", fmData, config);
  },
  /**
   * Retrieves the images associated with a given site ID.
   * @param {string} siteId - The ID of the site to retrieve images for.
   * @returns {Promise} A promise that resolves with the images associated with the given site ID.
   */
  getSiteImages: (siteId) => {
    return axios.post(editSiteUrl + siteId + "/images");
  },
  /**
   * Sends a GET request to the server to retrieve a list of states.
   * @returns {Promise} A promise that resolves with the list of states.
   */
  getStates: () => {
    return axios.get(editSiteUrl + "states");
  },
  /**
   * Sends a GET request to the server to retrieve facility data.
   * @returns {Promise} A promise that resolves with the facility data.
   */
  getFacility: () => {
    return axios.get(editSiteUrl + "facility");
  },
  /**
   * Sends a PUT request to the server to update the site information for the given site ID.
   * @param {string} siteId - The ID of the site to update.
   * @param {Object} payload - The data to update the site with.
   * @returns {Promise} A promise that resolves with the updated site data if the request is successful, or rejects with an error message if the request fails.
   */
  saveSiteInfo: (siteId, payload) => {
    return axios.put(editSiteUrl + siteId, payload);
  },
  /**
   * Retrieves the PI for a given site ID.
   * @param {string} siteId - The ID of the site to retrieve the PI for.
   * @returns {Promise} A Promise that resolves with the SitePi data.
   */
  getSitePi: (siteId) => {
    return axios.get(pIEdit + siteId + "/pi");
  },
  /**
   * Updates a Pi with the given ID using the provided payload.
   * @param {number} piId - The ID of the Pi to update.
   * @param {object} payload - The data to update the Pi with.
   * @returns A Promise that resolves with the updated Pi data.
   */
  updatePi: (piId, payload) => {
    return axios.put(pIEdit + piId, payload);
  },
  /**
   * Fetches the photo of a Pi with the given ID.
   * @param {string} piId - The ID of the Pi to fetch the photo for.
   * @returns {Promise} A promise that resolves with the photo data.
   */
  fetchPiPhoto: (piId) => {
    return axios.post(pIEdit + piId + "/logo");
  },
  /**
   * Uploads a photo for Pi profile picture.
   * @param {string} piId - The ID of Pi to upload the photo to.
   * @param {FormData} fmData - The form data containing the photo to upload.
   * @returns {Promise} A promise that resolves with the response from the server.
   */
  uploadPiPhoto: (piId, fmData) => {
    let config = {
      headers: { "content-type": "multipart/form-data" },
    };
    return axios.put(pIEdit + piId + "/logo", fmData, config);
  },
  /**
   * Sends a GET request to the specified endpoint to retrieve all therapeutic areas.
   * @returns {Promise} A promise that resolves with the response data.
   */
  getAllAreas: () => {
    return axios.get(pIEdit + "therapeutic");
  },
  /**
   * Retrieves all board certifications from the server.
   * @returns {Promise} A promise that resolves with the list of board certifications.
   */
  getAllBoardCert: () => {
    return axios.get(pIEdit + "board-certifications");
  },
  /**
   * Sends a GET request to the server to retrieve the dropdown list of number of studies.
   * @returns {Promise} A promise that resolves with the dropdown list of the total number of studies.
   */
  getAllNoOfStudies: () => {
    return axios.get(pIEdit + "numofstudies");
  },
  /**
   * Retrieves all studies for a given site ID.
   * @param {string} siteId - The ID of the site to retrieve studies for.
   * @returns {Promise} A promise that resolves with the response data from the server.
   */
  getAllStudies: (siteId) => {
    return axios.get(studyUrl + siteId + "/study");
  },
  /**
   * Updates a study with the given ID using the provided payload.
   * @param {number} studyId - The ID of the study to update.
   * @param {Object} payload - The data to update the study with.
   * @returns A Promise that resolves with the updated study data.
   */
  updateStudy: (studyId, payload) => {
    return axios.put(studyUrl + studyId, payload);
  },
  /**
   * Updates list of studies with the given site ID and payload.
   * @param {string} siteId - The ID of the site to update the studies for.
   * @param {Object} payload - The data to update the study with.
   * @returns {Promise} A promise that resolves with the updated studies data.
   */
  updateStudies: (siteId, payload) => {
    return axios.put(studyUrl + siteId + "/study", payload);
  },
  /**
   * Sets the no-pi flag for a given site ID using the provided payload.
   * @param {string} siteId - The ID of the site to set the no-pi flag for.
   * @param {Object} payload - The payload to send with the PUT request.
   * @returns {Promise} A promise that resolves with the response data from the PUT request.
   */
  setNoPiForSite: (siteId, payload) => {
    return axios.put(pIEdit + siteId + "/no-pi", payload);
  },
  /**
   * Sends a GET request to the server to retrieve the current version of the copyright.
   * @returns {Promise} A promise that resolves with the current version of the copyright.
   */
  getCopyright: () => {
    return axios.get(commonUrl + "copyright-version");
  },
  /**
   * Sends a GET request to retrieve the terms and conditions from the server.
   * @returns {Promise} A promise that resolves with the terms and conditions data.
   */
  getTerms: () => {
    return axios.get(commonUrl + "terms-and-condition");
  },
  /**
   * Sends a GET request to retrieve the opt-in terms from the server.
   * @returns {Promise} A promise that resolves with the opt-in terms data.
   */
  getOptinTerms: () => {
    return axios.get(commonUrl + "opt-in-terms");
  },
  /**
   * Sends a PUT request to the server to accept a premium search service for the given organization ID.
   * @param {string} orgId - The ID of the organization to accept the service for.
   * @returns A Promise that resolves to the response data from the server.
   */
  acceptService: (orgId) => {
    return axios.put(organizationUrl + "accept-service/" + orgId);
  },
  /**
   * Retrieves all sites associated with the current organization.
   * @returns {Promise} A promise that resolves with the list of sites.
   */
  getAllOrgSites: () => {
    let orgId = util.getOrganizationId();
    return axios.get(organizationUrl + orgId + "/all-sites");
  },
  /**
   * Retrieves all the organization's PIs from the server.
   * @returns {Promise} A promise that resolves with the response from the server.
   */
  getAllOrgPis: () => {
    let orgId = util.getOrganizationId();
    return axios.get(organizationUrl + orgId + "/pi");
  },
  /**
   * Deletes the logo of the organization with the given ID.
   * @returns {Promise} A promise that resolves with the result of the delete request.
   */
  deleteLogo: () => {
    let orgId = util.getOrganizationId();
    return axios.delete(organizationUrl + orgId + "/delete-logo");
  },
  /**
   * Deletes the logo of a site with the given ID.
   * @param {number} siteId - The ID of the site whose logo is to be deleted.
   * @returns {Promise} A promise that resolves with the result of the delete request.
   */
  deleteSiteLogo: (siteId) => {
    return axios.delete(editSiteUrl + siteId + "/logo");
  },
  /**
   * Deletes the profile picture of a Pi user with the given ID.
   * @param {string} piId - The ID of the Pi user whose profile picture is to be deleted.
   * @returns {Promise} A promise that resolves with the result of the delete request.
   */
  deletePiProfilePic: (piId) => {
    return axios.delete(pIEdit + piId + "/logo");
  },
  /**
   * Edits the image of a site with the given siteId and imgId using the provided form data.
   * @param {string} siteId - The ID of the site to edit.
   * @param {string} imgId - The ID of the image to edit.
   * @param {FormData} fmData - The form data containing the updated image.
   * @returns A promise that resolves with the updated site data.
   */
  editSiteImage: (siteId, imgId, fmData) => {
    let config = {
      headers: { "content-type": "multipart/form-data" },
    };
    return axios.put(
      editSiteUrl + siteId + "/image/" + imgId + "/update",
      fmData,
      config
    );
  },
  /**
   * Deletes an image associated with a site from the server.
   * @param {string} siteId - The ID of the site that the image belongs to.
   * @param {string} imgId - The ID of the image to be deleted.
   * @param {fmData} fmData - The data object containing the authentication token.
   * @returns A promise that resolves with the response from the server.
   */
  deleteSiteImage: (siteId, imgId, fmData) => {
    return axios.delete(editSiteUrl + siteId + "/image/" + imgId);
  },
  /**
   * Activates a study with the given ID.
   * @param {string} studyId - The ID of the study to activate.
   * @returns {Promise} A promise that resolves with the result of the activation request.
   */
  activateStudy: (studyId) => {
    return axios.put(studyUrl + studyId + "/activate");
  },
};
export default OrganizationService;
