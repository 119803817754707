import { Button, Col, notification, Row, Skeleton, Space } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import util from "../../utils/util";
import OrganizationService from "../services/organizationService";
import StudyTable from "./StudyTable";
import DraftWarningModal from "./DraftWarningModal";
import SitePageService from "../services/SitePageService";
import { updateSite } from "../features/sites";
import { saveDirtyForm } from "../features/dirtyForm";

// Renders the Study Profile Content component, which displays the study metrics for a given site and allows the user to edit and update them.
const StudyProfileContent = ({
  siteData,
  setSiteActiveKey,
  setEdited,
  showWarning,
  setShowWarning,
  exitTab,
  upcomingTab,
  edited,
  setUpcomingTab,
  setActiveTab,
}) => {
  const [count, setCount] = useState(0);
  const [studies, setStudies] = useState([]);
  const [tempStudies, setTempStudies] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  // Updates the studies list for the current site by sending a request to the server.
  const updateStudies = () => {
    setLoading(true);
    let payload = util.getUpdateStudieslistPayload(tempStudies);
    OrganizationService.updateStudies(siteData.siteId, payload)
      .then((res) => {
        setStudies(res.data.data);
        setTempStudies(res.data.data);
        SitePageService.getSiteInfoForPer(siteData.siteId)
          .then((res2) => {
            let temp = Object.assign({}, siteData);
            temp.percentageComplete = res2.data.data.percentageComplete;
            temp.isActive = res2.data.data.isActive;
            dispatch(updateSite(temp));
            setLoading(false);
            notification.success({
              message: "Successfully Updated studies...",
            });
          })
          .catch(() => {
            setLoading(false);
            notification.error({
              message: "Something went wrong ! Please refresh the page...",
            });
          });
        setEdited(false);
        dispatch(saveDirtyForm(false));
        if (upcomingTab === -1) {
          setSiteActiveKey(-1);
        } else {
          setActiveTab(upcomingTab);
        }
      })
      .catch(() => {
        notification.error({
          message: "Something went wrong!!! Please try again...",
        });
        setLoading(false);
      });
  };

  /**
   * Saves the current tab by updating the studies, setting the active key to the upcoming tab if it exists,
   * or setting the site active key to -1 if there is no upcoming tab. Also sets the edited state to false and
   * hides any warning messages.
   */
  const saveTab = () => {
    updateStudies();
    if (upcomingTab === -1) {
      setSiteActiveKey(-1);
    } else {
      setActiveTab(upcomingTab);
    }
    setUpcomingTab(-1);
    setEdited(false);
    dispatch(saveDirtyForm(false));
    setShowWarning(-1);
  };

  /**
   * useEffect hook that fetches all studies for a given site and sets the state variables
   * accordingly.
   * @param {{Object}} siteData - The site data object containing the site ID.
   */
  useEffect(() => {
    setDataLoading(true);
    if (siteData) {
      OrganizationService.getAllStudies(siteData.siteId)
        .then((res) => {
          setStudies(res.data.data);
          setTempStudies(res.data.data);
          setCount(res.data.data.length);
          setDataLoading(false);
        })
        .catch(() => {
          setDataLoading(false);
        });
    }
  }, []);
  return (
    <>
      <DraftWarningModal
        open={showWarning === "3" ? true : false}
        exitTab={exitTab}
        saveTab={saveTab}
        setShowWarning={setShowWarning}
        setUpcomingTab={setUpcomingTab}
      />
      <div className="study-profile-wrapper">
        <div className="pi-list-header">
          <div className="sites-header pi-header">
            Study Metrics <p>{count}</p>
          </div>
          <div className="add-pi-header"></div>
        </div>
        <p>
          -Sponsor, Protocol/Title and Principal Investigator will not be
          displayed in the CRIO Connect
        </p>
        {dataLoading ? (
          <Skeleton active />
        ) : (
          <div className="study-profile-body">
            <StudyTable
              studies={studies}
              tempStudies={tempStudies}
              setTempStudies={setTempStudies}
              setEdited={setEdited}
              loading={loading}
            />
            <Row gutter={[24, 24]}>
              <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                <Space style={{ marginTop: "30px" }}>
                  <Button
                    htmlType="submit"
                    className={edited ? "save-pi-button" : "cancel-pi-button"}
                    onClick={updateStudies}
                    loading={loading}
                    disabled={!edited}
                  >
                    Done
                  </Button>
                  <Button
                    type="secondary"
                    className="cancel-pi-button"
                    style={{ backgroundColor: "#bfbfbf" }}
                    onClick={() => {
                      setTempStudies(studies);
                      setUpcomingTab(-1);
                      if (edited) {
                        setShowWarning("3");
                      } else {
                        setSiteActiveKey(-1);
                      }
                    }}
                  >
                    Cancel
                  </Button>
                </Space>
              </Col>
            </Row>
          </div>
        )}
      </div>
    </>
  );
};

export default StudyProfileContent;
