import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  faCheckCircle,
  faChevronRight,
} from "@fortawesome/pro-solid-svg-icons";
import { useDispatch } from "react-redux";
import {
  Button,
  Card,
  Carousel,
  Checkbox,
  Col,
  notification,
  Row,
  Skeleton,
  Spin,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import util from "../../../utils/util";
import OrganizationService from "../../services/organizationService";
import { saveOrganization } from "../../features/organization";
import { saveAdmin } from "../../features/admin";
import LoginService from "../../services/loginService";
import AgreementImage from "../../../assets/images/iStock-1390529500.jpg";

/**
 * The Home component is the landing page for the CRIO Connect application. It displays
 * the terms of agreement and premium search opt-in options for the user to accept before
 * proceeding to the dashboard or setup page. If the user has already accepted the terms
 * and conditions, they will be redirected to the dashboard or setup page.
 * @returns The Home component JSX elements.
 */
const Home = () => {
  const history = useHistory();
  const [agreementChecked, setAgreementChecked] = useState(false);
  const [premiumChecked, setPremiumChecked] = useState(false);
  const [organizationData, setOrganizationData] = useState([]);
  const [organizationAdminData, setOrganizationAdminData] = useState();
  const [terms, setTerms] = useState("");
  const [optInTerms, setOptInTerms] = useState("");
  const mainRef = useRef();
  const dispatch = useDispatch();

  // Updates the state of the agreement checkbox when it is checked or unchecked.
  const onCheckboxChange = (values) => {
    setAgreementChecked(values.target.checked);
  };
  // Handles the change event of the premium checkbox and updates the state of the premiumChecked variable.
  const onPremiumCheckboxChange = (values) => {
    setPremiumChecked(values.target.checked);
  };

  /**
   * Accepts the terms and conditions for the organization admin and updates the state
   * accordingly. If the service is opted in, it will redirect to the dashboard or setup page.
   */
  const acceptTerms = () => {
    OrganizationService.acceptTermsAndCondition(
      organizationAdminData.organizationAdminId
    ).then((res) => {
      let orgAdmin = Object.assign({}, organizationData);
      orgAdmin.termsAndConditionChecked = 1;
      dispatch(saveAdmin(orgAdmin));
      if (organizationData.serviceOpted === 1) {
        if (
          organizationData.stepOneCompleted !== null &&
          organizationData.stepTwoCompleted !== null
        ) {
          window.location.href = "/dashboard";
        }
        history.push("/setup");
      }
      mainRef.current.next();
    });
  };

  // Accepts the premium service for the organization and redirects the user to the dashboard or setup page.
  const acceptPremium = () => {
    if (premiumChecked) {
      OrganizationService.acceptService(organizationData.organizationId)
        .then((res) => {
          if (
            organizationData.stepOneCompleted !== null &&
            organizationData.stepTwoCompleted !== null
          ) {
            window.location.href = "/dashboard";
          }
          history.push("/setup");
        })
        .catch((err) => {
          notification.error({
            message: "Something went wrong !! Please refresh the page ..",
          });
        });
    } else {
      if (
        organizationData.stepOneCompleted !== null &&
        organizationData.stepTwoCompleted !== null
      ) {
        window.location.href = "/dashboard";
      }
      history.push("/setup");
    }
  };

  /**
   * useEffect hook that fetches organization details, admin details, terms and opt-in terms
   * and sets the corresponding state variables. It also scrolls the window to the top of the page.
   */
  useEffect(() => {
    window.scrollTo(0, 0);
    OrganizationService.getTerms()
      .then((res) => {
        setTerms(res.data.data.value);
      })
      .catch((err) => {
        setTerms("Something went wrong !! Please refresh the page...");
      });
    OrganizationService.getOptinTerms()
      .then((res) => {
        setOptInTerms(res.data.data.value);
      })
      .catch((err) => {
        setOptInTerms("Something went wrong !! Please refresh the page...");
      });

    let orgId = util.getOrganizationId();
    OrganizationService.getOrganizationDetails(orgId)
      .then((res) => {
        dispatch(saveOrganization(res.data.data));
        setOrganizationData(res.data.data);
        let adminId = util.getAdminId();
        OrganizationService.getOrganizationAdminDetails(adminId)
          .then((resAdmin) => {
            dispatch(saveAdmin(resAdmin.data.data));
            setOrganizationAdminData(resAdmin.data.data);
            if (resAdmin.data.data.termsAndConditionChecked === 1) {
              if (
                res.data.data.stepOneCompleted !== null &&
                res.data.data.stepTwoCompleted !== null
              ) {
                window.location.href = "/dashboard";
              } else {
                history.push("/setup");
              }
            }
          })
          .catch(() => {
            LoginService.logout();
          });
      })
      .catch(() => {
        LoginService.logout();
      });
  }, []);
  return organizationAdminData &&
    organizationAdminData.termsAndConditionChecked !== 1 ? (
    <div className="home-body">
      <div className="home-body-container">
        <div className="home-body-container-img">
          <Row gutter={[24, 24]}>
            <Col xs={24} sm={24} md={10} lg={10} xl={10} className={"tc-left"}>
              <div className="hero-section">
                <h3>
                  Welcome to <br />
                  CRIO Connect
                </h3>
                <p>
                  In a few easy steps you can showcase your site's unique capabilities to a wider audience of sponsors. It's easy!
                  <ul>
                    <li>Build your profile.</li>
                    <li>Increase your visibility.</li>
                    <li>Enhance your reputation.</li>
                    <li>Get connected with new studies.</li>
                  </ul>
                </p>
              </div>
            </Col>

            <Col xs={24} sm={24} md={14} lg={14} xl={14} className={"tc-right"}>
              <Carousel
                dots={false}
                key={"mainCor"}
                ref={mainRef}
                accessibility={false}
                swipe={false}
                swipeToSlide={false}
                draggable={false}
                className={"agreement-card-wrapper"}
              >
                <Card className="agreement-card" key="1">
                  <div className="agreement-header">Terms of agreement</div>
                  <div className="agreement-body">
                    {terms === "" ? (
                      <Skeleton />
                    ) : (
                      <div
                        className="agreement-body-text"
                        dangerouslySetInnerHTML={{ __html: terms }}
                      ></div>
                    )}
                  </div>
                  <div className="agreement-footer">
                    <Checkbox
                      checked={agreementChecked}
                      onChange={onCheckboxChange}
                      className="agreement-footer-text"
                    >
                      Check here to indicate that you have read and agree to the
                      terms to using CRIO Vendors
                    </Checkbox>
                    <Button
                      type="primary"
                      className="agreement-footer-button"
                      disabled={!agreementChecked}
                      onClick={acceptTerms}
                    >
                      I agree, next
                      <span>
                        <FontAwesomeIcon icon={faChevronRight} />
                      </span>
                    </Button>
                  </div>
                </Card>
                {organizationData && organizationData.serviceOpted !== 1 ? (
                  <Card className="agreement-card" key="2">
                    <div className="agreement-header">
                      More ways to find connections
                    </div>
                    <div className="agreement-body">
                      {optInTerms === "" ? (
                        <Skeleton />
                      ) : (
                        <div className="agreement-body-text">
                          <div className="agreement-img">
                            <img src={AgreementImage} alt="AgreementImage" />
                          </div>
                          <div
                            // dangerouslySetInnerHTML={{ __html: terms }}
                            className="premium-body-text"
                          >
                            CRIO may be asked by sponsors or CROs to conduct
                            premium searches using access to information within
                            our system that is not available on CRIO Connect.{" "}
                            <span>Do you give CRIO permission</span>
                            <i>
                              {" "}
                              to mine your data for purposes of recommending
                              sites on these premium searches?
                            </i>
                            <br />
                            <br />
                            <Row>
                              <Col span={2}>
                                <FontAwesomeIcon
                                  icon={faCheckCircle}
                                  color="#138639"
                                  fontSize={22}
                                />
                              </Col>
                              <Col span={22}>
                                CRIO will never share your CRIO data directly
                                with sponsors or CROs; instead, CRIO will
                                indicate whether the premium search yielded
                                likely compatibility against pre-specified
                                target criteria (e.g., experience with a certain
                                class of investigational drug, or exposure to a
                                certain scale).
                              </Col>
                            </Row>
                            <br />
                            <Row>
                              <Col span={2}>
                                <FontAwesomeIcon
                                  icon={faCheckCircle}
                                  color="#138639"
                                  fontSize={22}
                                />
                              </Col>
                              <Col span={22}>
                                CRIO recognizes that data within your CRIO
                                system may not be complete, so CRIO will never
                                disqualify a site based on this premium search,
                                nor will CRIO disclose to the sponsor or CRO
                                which specific sites CRIO data mined. CRIO will
                                only be providing names of sites who appear to
                                meet the target criteria and will caveat that
                                the list is suggestive and not comprehensive.
                                Furthermore, CRIO will never use access to your
                                data to qualify or disqualify a statement you
                                have made to a sponsor or CRO.
                              </Col>
                            </Row>
                            <br />
                            <Row>
                              <Col span={2}>
                                <FontAwesomeIcon
                                  icon={faCheckCircle}
                                  color="#138639"
                                  fontSize={22}
                                />
                              </Col>
                              <Col span={22}>
                                Accordingly, there is very little risk to opting
                                in, and there is the potential to be proactively
                                advanced by CRIO for consideration on a given
                                study.
                              </Col>
                            </Row>
                            <br />
                            <br />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="agreement-footer">
                      <Checkbox
                        checked={premiumChecked}
                        onChange={onPremiumCheckboxChange}
                        className="agreement-footer-text"
                      >
                        Yes, I would like to opt in for CRIO’s premium search.
                        By opting in, you will let CRIO use your data for
                        referral to Sponsors/CROs.
                      </Checkbox>
                      <Button
                        type="primary"
                        className="agreement-footer-button"
                        onClick={acceptPremium}
                      >
                        {premiumChecked
                          ? "Opt in. Start CRIO Connect setup"
                          : "Opt in later. Start CRIO Connect setup"}
                        <span>
                          <FontAwesomeIcon icon={faChevronRight} />
                        </span>
                      </Button>
                    </div>
                  </Card>
                ) : null}
              </Carousel>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  ) : (
    <div className="home-loading">
      <Spin />
    </div>
  );
};

export default Home;
