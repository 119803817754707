import React, { useEffect, useState } from "react";
import { Divider, Input, Select, Skeleton, Space, Table } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import {
  faCaretUp,
  faSearch,
  faUserCircle,
} from "@fortawesome/pro-solid-svg-icons";
import moment from "moment";
import { dropdownData } from "../../constants/dumps";

/**
 * A component that displays a table of site data with various columns such as site name, organization name, principal investigators, and profile status.
 * @param {{sites}} sites - An array of site objects to display in the table.
 * @param {{setSearch}} setSearch - A function to set the search value for the table.
 * @param {{loading}} loading - A boolean value indicating whether the table is currently loading data.
 * @returns A table component displaying the site data.
 */
const SideDataTable = ({ sites, setSearch, loading }) => {
  const [pageSize, setPageSize] = useState("5");
  const [tableData, setTableData] = useState();
  const [showAll, setShowAll] = useState(false);

  /**
   * An array of objects representing columns in a table. Each object contains a title and a render function
   * that returns the content for that column. The columns are then mapped to set the width of each column.
   */
  let columns = [
    {
      title: "Site",
      render: (__, record) => {
        return (
          <div className="table_site_name pop-reg-666">
            {record.siteLogo ? (
              <img src={record.siteLogo} alt="Site Logo" />
            ) : (
              <Skeleton.Image
                style={{
                  maxWidth: "60px",
                  maxHeight: "30px",
                  marginRight: "10px",
                  padding: "18px",
                }}
              />
            )}
            {record.siteName}
          </div>
        );
      },
    },
    {
      title: "Organization",
      dataIndex: "organizationName",
      render: (organizationName) => (
        <div className="pop-reg-666">{organizationName}</div>
      ),
    },
    {
      title: "Principal Investigators",
      render: (__, record) => {
        let allPi = [];
        let showPi = [];
        if (record.piDataResponseDtos) {
          record.piDataResponseDtos.map((pi, index) => {
            if (index < 3) {
              showPi.push(
                <li key={pi.piId}>
                  {pi.profilePic ? (
                    <img src={pi.profilePic} alt="PI Img" />
                  ) : (
                    <FontAwesomeIcon
                      icon={faUserCircle}
                      fontSize={20}
                      style={{
                        marginRight: "10px",
                        color: "rgb(153, 153, 153)",
                        border: "1px solid #009ceb",
                        borderRadius: "50%",
                      }}
                    />
                  )}{" "}
                  {pi.piName}
                </li>
              );
            }
            allPi.push(
              <li key={pi.piId}>
                {pi.profilePic ? (
                  <img src={pi.profilePic} alt="PI Img" />
                ) : (
                  <FontAwesomeIcon
                    icon={faUserCircle}
                    fontSize={20}
                    style={{
                      marginRight: "10px",
                      color: "rgb(153, 153, 153)",
                      border: "1px solid #009ceb",
                      borderRadius: "50%",
                    }}
                  />
                )}{" "}
                {pi.piName}
              </li>
            );
          });
        }
        return (
          <>
            <div className="principal-list">
              {showAll === record.siteId ? allPi : showPi}
              {record.piDataResponseDtos &&
              record.piDataResponseDtos.length > 3 ? (
                <div className="extra-pi-icon">
                  {showAll !== record.siteId ? (
                    <FontAwesomeIcon
                      icon={faCaretDown}
                      className="pi-expand-icon"
                      onClick={() => {
                        setShowAll(record.siteId);
                      }}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faCaretUp}
                      className="pi-expand-icon"
                      onClick={() => {
                        setShowAll(-1);
                      }}
                    />
                  )}
                </div>
              ) : null}
            </div>
          </>
        );
      },
    },
    {
      title: "Profile Status",
      render: (__, record) => {
        return (
          <div className="table-profile-status-wrapper">
            <div>
              <div className="table-profile-status pop-reg-666">
                <span
                  style={{ color: record.isActive ? "#009245" : "#C04040" }}
                >
                  {record.isActive ? "Public" : "Unpublished"}
                </span>{" "}
                {record.statusChangedDate ? "since" : ""}
              </div>
              <div className="table-profile-status-date pop-reg-666">
                {record.statusChangedDate
                  ? moment(record.statusChangedDate).format("DD-MMM-yyyy h:mmA")
                  : null}
              </div>
            </div>
            <div
              className="site-right-arrow"
              style={{ display: record.action ? "block" : "none" }}
            >
              <FontAwesomeIcon
                icon={faChevronRight}
                onClick={() => {
                  window.open("/site/" + record.siteId, "_blank");
                }}
              />
            </div>
          </div>
        );
      },
    },
  ];
  columns = columns.map((el) => {
    el.onCell = (_, index) => ({
      width: 100 / columns.length + "%",
    });
    return el;
  });

  /**
   * Updates the search state with the value of the search input field.
   * @param {{Event}} e - The event object triggered by the search input field.
   */
  const onSearch = (e) => {
    setSearch(e.target.value);
  };

  // useEffect hook that updates the table data whenever the sites prop changes.
  useEffect(() => {
    setTableData(sites);
  }, [sites]);

  /**
   * Renders the header of a table with a dropdown to select the number of entries to show,
   * a search bar to search for entries by name or site, and the column titles.
   * @returns A JSX element representing the table header.
   */
  const tableHeader = () => {
    return (
      <>
        <div className="title-container">
          <div className="table-header-wrapper">
            <div className="table-header-wrapper-title">
              <Space>
                <p>Show</p>
                <div className="table-header-wrapper-title-dropdown">
                  <Select
                    defaultValue="5"
                    onChange={(value) => {
                      setPageSize(value);
                    }}
                    options={dropdownData}
                    style={{ width: "100%" }}
                    className="crio-input"
                  />
                </div>
                <p>Entries</p>
              </Space>
            </div>
            <div className="table-header-wrapper-search">
              <Input
                placeholder="Search by Name, Site"
                size="large"
                onChange={onSearch}
                style={{ width: "100%" }}
                suffix={
                  <FontAwesomeIcon
                    icon={faSearch}
                    className="dashboard-search-icon"
                  />
                }
                className="crio-input"
              />
            </div>
          </div>
          <Divider type="horizontal" className="horizontal-divider-class" />
        </div>
        <div className="table-header-column">
          {columns.map((col) => {
            return (
              <div
                className="col-title"
                style={{ width: 100 / columns.length + "%" }}
                key={col.title}
              >
                {col.title}
              </div>
            );
          })}
        </div>
      </>
    );
  };

  return (
    <Table
      columns={columns}
      dataSource={tableData}
      title={tableHeader}
      className={"ant-side-table"}
      showHeader={false}
      loading={loading}
      pagination={{
        pageSize: pageSize,
      }}
      onRow={(record, rowIndex) => {
        return {
          style: { opacity: record.isActive ? "1" : "0.7" },
          onClick: (event) => {
            if (
              !event.target.className.toString().includes("SVGAnimatedString")
            ) {
              window.open("/site/" + record.siteId, "_blank");
            }
          },
          onMouseEnter: (event) => {
            setTableData(
              tableData.map((el, index) => {
                if (rowIndex === index) {
                  el.action = "visible";
                  return el;
                }
                return el;
              })
            );
          },
          onMouseLeave: (event) => {
            setTableData(
              tableData.map((el, index) => {
                if (rowIndex === index) {
                  delete el.action;
                  return el;
                }
                return el;
              })
            );
          },
        };
      }}
    />
  );
};
export default SideDataTable;
