import util from "utils/util";
import { Col, Checkbox, Form, Input, Row } from "antd";
function WebsiteField({ organizationWebsiteUrl, confirm }) {
  const validateUrl = (_, value) => {
    if (!value) {
      return Promise.resolve();
    }

    // Regular expression to validate URL with optional protocol and www
    const urlRegex =
      /^(?:(?:https?|ftp):\/\/)?(?:www\.)?([^\s/$.?#].[^\s]*\.[^\s]{2,})$/i;

    if (urlRegex.test(value.replace("www.", "").replace(".www", ""))) {
      return Promise.resolve();
    }

    return Promise.reject(new Error("Please enter a valid Website URL"));
  };

  return (
    <Row gutter={[24, 24]} style={{ marginBottom: "10px" }}>
      <Col xs={24} sm={24} md={10} lg={10} xl={10}>
        <div className="op-label">Organization Website URL</div>
      </Col>
      <Col xs={24} sm={24} md={14} lg={14} xl={14}>
        <Form.Item
          name={"organizationWebsiteUrl"}
          rules={[
            {
              validator: validateUrl,
            },
          ]}>
          <Input
            className="crio-input"
            placeholder="Enter Organization Website URL"
          />
        </Form.Item>
        <Form.Item name={"uniformUrl"} valuePropName="checked">
          <Checkbox
            className="op-checkbox"
            disabled={util.isEmptyStr(organizationWebsiteUrl)}
            onChange={(e) => {
              confirm("URL", e);
            }}>
            Apply this URL to all sites
          </Checkbox>
        </Form.Item>
      </Col>
    </Row>
  );
}

export default WebsiteField;
