import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  Row,
  Col,
  Input,
  Divider,
  Checkbox,
  Button,
  Skeleton,
  Result,
  FloatButton,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook, faFilter, faSearch } from "@fortawesome/pro-solid-svg-icons";
import SiteCard from "../../components/SiteCard";
import SitePageService from "../../services/SitePageService";

// The Dashboard component displays a list of sites and allows the user to filter and sort them.
function Dashboard() {
  const [sites, setSites] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [searchName, setSearchName] = useState("");
  const [siteCount, setSiteCount] = useState(0);
  const [publicCheck, setPublicCheck] = useState(false);
  const [unpublishedCheck, setUnpublishedCheck] = useState(false);
  const [publicCount, setPublicCount] = useState(0);
  const [unpublishedCount, setUnpublishedCount] = useState(0);
  const [selectedAreas, setSelectedAreas] = useState([]);
  const organizationData = useSelector((state) => state.organization);
  const areasOption = useSelector((state) => state.areas);
  const [sortBy, setSortBy] = useState(0);

  /**
   * Clears the current selection and updates the search results based on the selected areas,
   * search name, and sort order.
   */
  const clearSelection = () => {
    setPublicCheck(false);
    setUnpublishedCheck(false);
    searchSites(selectedAreas, false, false, searchName, sortBy);
  };

  /**
   * Searches for sites based on the given parameters and sets the state variables
   * accordingly.
   * @param {{string[]}} sa - The list of selected therapeutic areas to search for.
   * @param {{boolean}} pc - Whether or not to include published sites in the search.
   * @param {{boolean}} uc - Whether or not to include unpublished sites in the search.
   * @param {{string}} sn - The name of the site to search for.
   * @param {{number}} sb - The sort by parameter. 0 for site name, 1 for all time viewer.
   * @returns None
   */
  const searchSites = (sa, pc, uc, sn, sb) => {
    setLoading(true);
    setPageNumber(0);
    let payload = {
      therapeuticList: sa,
      publish: pc,
      unPublish: uc,
      searchSiteName: sn,
    };
    let sBy = sb === 0 ? "site_name" : "all_time_viewer";
    SitePageService.getAllSites(
      organizationData.organizationId,
      payload,
      10,
      0,
      sBy
    )
      .then((res) => {
        setSites(res.data.data.dataResponseDtos);
        setSiteCount(res.data.data.siteCount);
        setPublicCount(res.data.data.publishedCount);
        setUnpublishedCount(res.data.data.unPublishedCount);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  /**
   * Fetches more results from the server and updates the state with the new data.
   * @returns None
   */
  const fetchMoreResult = () => {
    let payload = {
      therapeuticList: selectedAreas,
      publish: publicCheck,
      unPublish: unpublishedCheck,
      searchSiteName: searchName,
    };
    let sBy = sortBy === 0 ? "site_name" : "all_time_viewer";
    SitePageService.getAllSites(
      organizationData.organizationId,
      payload,
      10,
      pageNumber + 1,
      sBy
    ).then((res) => {
      setSites([...sites, ...res.data.data.dataResponseDtos]);
      setSiteCount(res.data.data.siteCount);
      setPublicCount(res.data.data.publishedCount);
      setUnpublishedCount(res.data.data.unPublishedCount);
    });
    setPageNumber(pageNumber + 1);
  };

  /**
   * useEffect hook that fetches site data from the server and updates the state with the results.
   * @param {{object}} organizationData - The organization data object.
   */
  useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(true);
    if (organizationData.length !== 0) {
      let payload = {
        therapeuticList: [],
        publish: publicCheck,
        unPublish: unpublishedCheck,
        searchSiteName: searchName,
      };
      SitePageService.getAllSites(
        organizationData.organizationId,
        payload,
        10,
        0,
        "site_name"
      )
        .then((res) => {
          setSites(res.data.data.dataResponseDtos);
          setSiteCount(res.data.data.siteCount);
          setPublicCount(res.data.data.publishedCount);
          setUnpublishedCount(res.data.data.unPublishedCount);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, [organizationData]);

  return (
    <div className="body-container dashboard-body">
      <FloatButton.BackTop />
      <Row gutter={[24, 24]}>
        <Col xs={24} sm={24} md={5} lg={5} xl={5}>
          <div className="dashboard-sites-left">
            <div className="sites-header dashboard-sites-header">
              Your Dashboard
            </div>
            <Input
              className="crio-input dashboard-search"
              placeholder="Search by Site"
              suffix={
                <FontAwesomeIcon
                  icon={faSearch}
                  className="dashboard-search-icon"
                  onClick={() => {
                    searchSites(
                      selectedAreas,
                      publicCheck,
                      unpublishedCheck,
                      searchName,
                      sortBy
                    );
                  }}
                />
              }
              onChange={(e) => {
                setSearchName(e.target.value);
              }}
              onPressEnter={() => {
                searchSites(
                  selectedAreas,
                  publicCheck,
                  unpublishedCheck,
                  searchName,
                  sortBy
                );
              }}
            />
            <div className="sites-left-header">
              <div>
                <FontAwesomeIcon
                  icon={faFilter}
                  style={{ marginRight: "10px" }}
                />
                Filter
              </div>
              <p onClick={clearSelection}>clear</p>
            </div>
            <div className="sites-left-body">
              <Checkbox
                className={
                  "sites-checkbox " +
                  (publicCheck ? "sites-checkbox-checked" : "")
                }
                checked={publicCheck}
                onChange={(e) => {
                  setPublicCheck(e.target.checked);
                  searchSites(
                    selectedAreas,
                    e.target.checked,
                    unpublishedCheck,
                    searchName,
                    sortBy
                  );
                }}
              >
                Public{" "}
                <span className="sites-checkbox-span">{publicCount}</span>
              </Checkbox>
              <Divider type="horizontal" className="horizontal-divider-class" />
              <Checkbox
                className={
                  "sites-checkbox " +
                  (unpublishedCheck ? "sites-checkbox-checked" : "")
                }
                checked={unpublishedCheck}
                onChange={(e) => {
                  setUnpublishedCheck(e.target.checked);
                  searchSites(
                    selectedAreas,
                    publicCheck,
                    e.target.checked,
                    searchName,
                    sortBy
                  );
                }}
              >
                Unpublished{" "}
                <span className="sites-checkbox-span">{unpublishedCount}</span>
              </Checkbox>
              <Divider type="horizontal" className="horizontal-divider-class" />
            </div>
            <div className="sites-left-header">
              <div>
                <FontAwesomeIcon
                  icon={faBook}
                  style={{ marginRight: "10px" }}
                />
                Therapeutic Area
              </div>
              <p
                onClick={() => {
                  searchSites(
                    [],
                    publicCheck,
                    unpublishedCheck,
                    searchName,
                    sortBy
                  );
                  setSelectedAreas([]);
                }}
              >
                clear
              </p>
            </div>
            <div className="sites-left-body dashboard-checkbox-wrapper">
              <p>Select up to 5</p>
              <Checkbox.Group
                className="dashboard-area-container"
                onChange={(e) => {
                  setSelectedAreas(e);
                  searchSites(
                    e,
                    publicCheck,
                    unpublishedCheck,
                    searchName,
                    sortBy
                  );
                }}
                value={selectedAreas}
              >
                {areasOption.map((el) => {
                  return (
                    <Checkbox
                      className={
                        "sites-checkbox " +
                        (selectedAreas.includes(el.therapeuticId)
                          ? "sites-checkbox-checked"
                          : "")
                      }
                      key={el.therapeuticId}
                      value={el.therapeuticId}
                      disabled={
                        selectedAreas.length <= 4
                          ? false
                          : selectedAreas.includes(el.therapeuticId)
                          ? false
                          : true
                      }
                    >
                      {el.therapeuticName}
                    </Checkbox>
                  );
                })}
              </Checkbox.Group>
            </div>
            <Divider type="horizontal" className="horizontal-divider-class" />
          </div>
        </Col>
        <Col xs={0} sm={0} md={1} lg={1} xl={1}>
          <Divider type="vertical" className="dashboard-divider" />
        </Col>
        <Col xs={24} sm={24} md={18} lg={18} xl={18}>
          <div>
            <div className="dashboard-sites-wrapper">
              <div className="dashboard-sites-right-header">
                Sites <span>{siteCount}</span>
              </div>
              <div className="dashboard-sites-sort">
                <p>View By:</p>
                <div className="dashboard-sites-sort-buttons-wrapper">
                  <div
                    className={
                      sortBy === 0
                        ? "dashboard-sites-sort-buttons-active"
                        : "dashboard-sites-sort-buttons"
                    }
                    onClick={() => {
                      if (sortBy !== 0) {
                        setSortBy(0);
                        searchSites(
                          selectedAreas,
                          publicCheck,
                          unpublishedCheck,
                          searchName,
                          0
                        );
                      }
                    }}
                  >
                    Alphabetical
                  </div>
                  <div
                    className={
                      sortBy === 1
                        ? "dashboard-sites-sort-buttons-active"
                        : "dashboard-sites-sort-buttons"
                    }
                    onClick={() => {
                      if (sortBy !== 1) {
                        setSortBy(1);
                        searchSites(
                          selectedAreas,
                          publicCheck,
                          unpublishedCheck,
                          searchName,
                          1
                        );
                      }
                    }}
                  >
                    Most viewed
                  </div>
                </div>
              </div>
            </div>
            <div className="dashboard-site-cards-wrapper">
              {loading ? (
                <Skeleton active />
              ) : !sites || sites.length === 0 ? (
                <Result
                  status="warning"
                  title="No match found. Please update your search."
                />
              ) : (
                <InfiniteScroll
                  dataLength={sites.length}
                  next={fetchMoreResult}
                  hasMore={sites.length !== siteCount}
                  loader={<Skeleton active />}
                  style={{ overflow: "display" }}
                >
                  {sites.map((el) => {
                    return <SiteCard siteData={el} key={el.siteId} />;
                  })}
                </InfiniteScroll>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Dashboard;
