import React, { useEffect, useRef, useState } from "react";
import { Col, Row, Select, Space, Switch, Table, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import LoadingModal from "./LoadingModal";
import util from "../../utils/util";
import { saveDirtyForm } from "../features/dirtyForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/pro-solid-svg-icons";

/**
 * A component that displays a table of studies with various information such as sponsor, protocol/title, phase, enrollment, therapeutic area, and principal investigator. The table also allows the user to change the status of a study from active to inactive and vice versa.
 * @param {{Array}} studies - An array of study objects to display in the table.
 * @param {{function}} setStudies - A function to set the studies array.
 * @param {{Array}} tempStudies - An array of temporary study objects to display in the table.
 * @param {{function}} setTempStudies - A function to set the temporary studies array.
 * @param {{function}} setEdited - A function to set whether or not the table has been edited
 */
const StudyTable = ({
  studies,
  tempStudies,
  setTempStudies,
  setEdited,
  loading,
}) => {
  const areasOption = useSelector((state) => state.areas);
  const dispatch = useDispatch();
  const tableRef = useRef();

  const handleScroll = (direction) => {
    const table = tableRef.current;
    let tableContainer = table.querySelector(".ant-table-content");
    if (!tableContainer) {
      tableContainer = table.querySelector(".ant-table-body");
    }
    console.log(tableContainer, table);
    if (tableContainer) {
      let newScrollLeft;
      if (direction === "right") {
        newScrollLeft =
          tableContainer.scrollWidth - tableContainer.offsetWidth + 50;
      } else if (direction === "left") {
        newScrollLeft = 0;
      }
      smoothScroll(tableContainer, newScrollLeft);
    }
  };

  const smoothScroll = (element, targetScrollLeft, duration = 300) => {
    const startScrollLeft = element.scrollLeft;
    const scrollDistance = targetScrollLeft - startScrollLeft;
    let startTime = null;

    const animateScroll = (timestamp) => {
      if (!startTime) startTime = timestamp;
      const elapsed = timestamp - startTime;
      const progress = Math.min(elapsed / duration, 1);
      const easeProgress = easeInOutCubic(progress);
      element.scrollLeft = startScrollLeft + scrollDistance * easeProgress;

      if (elapsed < duration) {
        requestAnimationFrame(animateScroll);
      }
    };
    requestAnimationFrame(animateScroll);
  };
  const easeInOutCubic = (t) => {
    return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
  };

  /**
   * An array of objects that define the columns for a table. Each object represents a single column
   * and contains properties such as title, dataIndex, key, and render. The render property is a function
   * that returns the JSX to be rendered in the cell. The first column is fixed to the left and contains
   * a switch to toggle the active status of the record.
   * @type {Array}
   */
  const columns = [
    {
      title: (
        <div className="left-scroll-icon">
          <FontAwesomeIcon
            icon={faChevronLeft}
            onClick={() => handleScroll("left")}
          />
        </div>
      ),
      width: 150,
      dataIndex: "isActive",
      key: "isActive",
      fixed: "left",
      render: (_, record) => (
        <Space size={10}>
          <div
            className="site-header-circle"
            style={{
              backgroundColor: record.isActive ? "#13b639" : "#999999",
            }}
          />
          <Switch
            checkedChildren="ON"
            unCheckedChildren="OFF"
            defaultChecked={record.isActive ? true : false}
            onChange={(e) => {
              let tempStudy = tempStudies.map((el) => {
                if (record.studyId === el.studyId) {
                  el.isActive = e ? 1 : 0;
                  return el;
                }
                return el;
              });
              setEdited(true);
              dispatch(saveDirtyForm(true));
              setTempStudies(tempStudy);
            }}
          />
        </Space>
      ),
    },
    {
      title: "Sponsor",
      dataIndex: "sponsor",
      key: "sponsor",
    },
    {
      title: "Protocol/Title",
      key: "protocolTitle",
      render: (_, record) => (
        <Tooltip
          placement="bottom"
          title={
            <div className="study-tooltip">
              <Row>
                <Col span={9}>Protocol /</Col>
                <Col span={9}>Title</Col>
              </Row>
              <Row>
                <Col span={9}>
                  <p>Protocol:</p>
                </Col>
                <Col span={15}>
                  <p>
                    <span>{record.protocol}</span>
                  </p>
                </Col>
              </Row>
              <Row>
                <Col span={9}>
                  <p>Title:</p>
                </Col>
                <Col span={15}>
                  <p>
                    <span>{record.title}</span>
                  </p>
                </Col>
              </Row>
            </div>
          }
          color={"#fff"}
        >
          <a>{record.protocol}</a>
        </Tooltip>
      ),
    },
    {
      title: "Phase",
      dataIndex: "phase",
      key: "phase",
      render: (phase) => {
        return phase ? phase : "-";
      },
    },
    {
      title: "Enrollment Started Date",
      dataIndex: "enrollmentStartDate",
      key: "enrollmentStartDate",
      render: (enrollmentStartDate) => enrollmentStartDate,
    },
    {
      title: "Number Enrolled",
      dataIndex: "enrollmentNoPerStudy",
      key: "enrollmentNoPerStudy",
      render: (enrollmentNoPerStudy) => {
        return enrollmentNoPerStudy ? enrollmentNoPerStudy + " Subjects" : "-";
      },
    },
    {
      title: "*Therapeutic Area - Indication",
      dataIndex: "therapeuticAreaIndicationId",
      key: "therapeuticAreaIndicationId",
      render: (__, record) => (
        <Select
          className="crio-input study-select"
          placeholder="-Select-"
          style={{ width: "120px", padding: "5px 0" }}
          suffixIcon={<FontAwesomeIcon icon={faCaretDown} />}
          getPopupContainer={(trigger) => trigger.parentNode}
          defaultValue={
            record.therapeuticAreaIndicationId
              ? record.therapeuticAreaIndicationId.therapeuticName
              : null
          }
          onChange={(e) => {
            let tempStudy = tempStudies.map((el) => {
              if (record.studyId === el.studyId) {
                let area = util.getAreaObject(e, areasOption);
                el.therapeuticAreaIndicationId = area;
                return el;
              }
              return el;
            });
            setEdited(true);
            dispatch(saveDirtyForm(true));
            setTempStudies(tempStudy);
          }}
        >
          {areasOption.length !== 0 &&
            areasOption.map((el) => {
              return (
                <Select.Option
                  key={el.therapeuticId}
                  value={el.therapeuticName}
                >
                  {el.therapeuticName}
                </Select.Option>
              );
            })}
        </Select>
      ),
    },
    {
      title: "Principal Investigator",
      dataIndex: "principalInvestigatorId",
      key: "principalInvestigatorId",
      render: (principalInvestigatorId) =>
        principalInvestigatorId ? principalInvestigatorId.piName : "-",
    },
  ];

  /**
   * useEffect hook that updates the tempStudies state when the studies prop changes.
   * @param {{Array}} studies - The studies array to compare against.
   */
  useEffect(() => {
    if (studies) {
      let tempSt = tempStudies.map((el) => {
        for (let i = 0; i < studies.length; i++) {
          if (studies[i].studyId === el.studyId) {
            el.isActive = studies[i].isActive;
            return el;
          }
        }
      });
      setTempStudies(tempSt);
    }
  }, [studies]);
  return (
    <>
      <div className="right-scroll-icon">
        <FontAwesomeIcon
          icon={faChevronRight}
          onClick={() => handleScroll("right")}
        />
      </div>
      <LoadingModal open={loading} />
      <Table
        columns={columns}
        dataSource={studies}
        scroll={
          studies && studies.length > 5
            ? { x: 1100, y: 500 }
            : {
                x: 1100,
              }
        }
        ref={tableRef}
        className={"study-table"}
        pagination={false}
      />
    </>
  );
};
export default StudyTable;
