import util from "utils/util";
import { Checkbox, Col, Form, Input, Row } from "antd";
import PublicDisplayNameField from "js/pages/dashboard/OrgProfile/OrgProfileForm/PublicDisplayNameField";
import UniformDisplayNameField from "js/pages/dashboard/OrgProfile/OrgProfileForm/UniformDisplayNameField";
function DisplayNameField({ confirm, publicDisplayName }) {
  return (
    <Row gutter={[24, 24]} style={{ marginBottom: "10px" }}>
      <Col xs={24} sm={24} md={10} lg={10} xl={10}>
        <div className="op-label">Public Display Name</div>
      </Col>
      <Col xs={24} sm={24} md={14} lg={14} xl={14}>
        <PublicDisplayNameField />
        <UniformDisplayNameField
          confirm={confirm}
          publicDisplayName={publicDisplayName}
        />
      </Col>
    </Row>
  );
}

export default DisplayNameField;
