import { createSlice } from "@reduxjs/toolkit";

// This slice of the Redux store manages the state of the areas array.
export const areasSlice = createSlice({
  name: "areas",
  initialState: [],
  reducers: {
    saveAreas: (state, action) => {
      return action.payload;
    },
    removeAreas: (state, action) => {
      return [];
    },
  },
});

export const { saveAreas, removeAreas } = areasSlice.actions;
export default areasSlice.reducer;
