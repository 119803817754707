import { Form, Input } from "antd";
function PublicDisplayNameField({}) {
  return (
    <Form.Item name={"publicDisplayName"}>
      <Input className="crio-input" placeholder="Enter Public Display Name" />
    </Form.Item>
  );
}

export default PublicDisplayNameField;
