import { createSlice } from "@reduxjs/toolkit";

// This slice of the Redux store manages the organization data.
export const organizationSlice = createSlice({
  name: "organization",
  initialState: [],
  reducers: {
    saveOrganization: (state, action) => {
      return action.payload;
    },
    removeOrganization: (state, action) => {
      return [];
    },
  },
});

export const { saveOrganization, removeOrganization } =
  organizationSlice.actions;
export default organizationSlice.reducer;
