import util from "../../../../../utils/util";
import { Form, Checkbox } from "antd";
function UniformDisplayNameField({ confirm, publicDisplayName }) {
  return (
    <Form.Item name={"uniformDisplayName"} valuePropName="checked">
      <Checkbox
        className="op-checkbox"
        disabled={util.isEmptyStr(publicDisplayName)}
        onChange={(e) => {
          confirm("display name", e);
        }}>
        Apply this name to all sites
      </Checkbox>
    </Form.Item>
  );
}

export default UniformDisplayNameField;
