import util from "utils/util";

import { Button, Col, Form, Row, Space, notification } from "antd";
import useApplyAllModal from "../../../../hooks/useApplyAllModal";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import OrganizationService from "../../../../services/organizationService";
import { saveOrganization } from "js/features/organization";
import LogoField from "./LogoField";
import UniformLogoField from "./UniformLogoField";
import OrganizationNameField from "./OrganizationNameField";
import InquiryInformation from "./InquiryInformation";
import DescriptionField from "js/pages/dashboard/OrgProfile/OrgProfileForm/DescriptionField";
import WebsiteField from "js/pages/dashboard/OrgProfile/OrgProfileForm/WebsiteField";
import DisplayNameField from "js/pages/dashboard/OrgProfile/OrgProfileForm/DisplayNameField";

function OrgProfileForm({ organizationData, setPremiumChecked }) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [logo, setLogo] = useState();
  const [confirm, contextHolder] = useApplyAllModal(form, organizationData);
  const [wordCount, setWordCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [activeRevert, setActiveRevert] = useState(false);

  const publicDisplayName = Form.useWatch("publicDisplayName", form);
  const organizationWebsiteUrl = Form.useWatch("organizationWebsiteUrl", form);
  const organizationDescription = Form.useWatch(
    "organizationDescription",
    form,
  );

  /**
   * Saves the organization data to the server and updates the state with the new data.
   * @param {{Object}} values - The new values to save to the organization profile.
   */
  const saveOrgData = (values) => {
    setLoading(true);
    let payload = util.getOrgProfilePayload(values, organizationData);
    OrganizationService.saveOrganizationDetails(payload)
      .then(() => {
        dispatch(saveOrganization(payload));
        setLoading(false);
        window.location.href = "/dashboard";
      })
      .catch(() => {
        setLoading(false);
        notification.error({
          message: "Error while saving Organization profile !!!",
        });
      });
  };

  const handleRevert = () => {
    let formData = util.getOrgProfileFormData(organizationData);
    form.setFields(formData);
    setActiveRevert(false);
  };

  useEffect(() => {
    let formData = util.getOrgProfileFormData(organizationData);
    form.setFields(formData);
    if (organizationData.length !== 0) {
      organizationData.organizationDescription &&
        setWordCount(
          organizationData.organizationDescription.trim().split(/\s+/).length,
        );
      OrganizationService.fetchLogo(organizationData.organizationId).then(
        (res) => {
          setLogo(res.data.data);
        },
      );
    }
    if (organizationData.serviceOpted === 1) {
      setPremiumChecked(true);
    }
  }, [organizationData]);

  return (
    <>
      {contextHolder}

      <Col xs={24} sm={24} md={17} lg={17} xl={17}>
        <div className="op-right-header">
          Organization Information
          <p className="op-p">* Indicates a required field</p>
        </div>
        <div className="op">
          <Form
            form={form}
            onFinish={saveOrgData}
            onChange={() => {
              setActiveRevert(true);
            }}>
            <Row gutter={[24, 24]}>
              <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                <div className="op-label">Organization Logo</div>
                <div className="op-logo-wrapper">
                  <LogoField logo={logo} organizationData={organizationData} />
                </div>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={14}
                lg={14}
                xl={14}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "end",
                }}>
                <UniformLogoField confirm={confirm} logo={logo} />
              </Col>
            </Row>
            <OrganizationNameField organizationData={organizationData} />
            <DisplayNameField
              publicDisplayName={publicDisplayName}
              confirm={confirm}
            />
            <WebsiteField
              confirm={confirm}
              organizationWebsiteUrl={organizationWebsiteUrl}
            />
            <DescriptionField
              organizationDescription={organizationDescription}
              confirm={confirm}
              wordCount={wordCount}
              setWordCount={setWordCount}
            />
            <InquiryInformation />
          </Form>
        </div>
        <Row gutter={[24, 24]} style={{ marginBottom: "10px" }}>
          <Col
            xs={24}
            sm={24}
            md={10}
            lg={10}
            xl={10}
            style={{ margin: "30px 0 20px 0" }}>
            <Space>
              <Form
                form={form}
                onFinish={saveOrgData}
                scrollToFirstError={true}>
                <Form.Item>
                  <Button
                    htmlType="submit"
                    className="warning-footer-left"
                    loading={loading}>
                    Save
                  </Button>
                  <Button
                    type="text"
                    size={"large"}
                    className="warning-footer-right"
                    onClick={() => {
                      handleRevert();
                    }}
                    disabled={!activeRevert}>
                    Revert Changes
                  </Button>
                </Form.Item>
              </Form>
            </Space>
          </Col>
        </Row>
      </Col>
    </>
  );
}

export default OrgProfileForm;
