import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";

/**
 * A component that displays a table of studies with indication, phase, number enrolled, and start year.
 * The table can be expanded to show more studies or collapsed to show fewer studies.
 * @param {{Array}} studies - An array of study objects to display in the table.
 * @returns A React component that displays the studies table.
 */
const StudiesTable = ({ studies }) => {
  const [limit, setLimit] = useState(6);
  const [hasMore, setHasMore] = useState(0);

  // Increases the limit of items to load and sets hasMore to 0, indicating that there are no more items to load.
  const loadMore = () => {
    setLimit(limit + hasMore);
    setHasMore(0);
  };

  // Loads less data by setting the limit to 6 and updating the hasMore state.
  const loadLess = () => {
    setHasMore(limit - 6);
    setLimit(6);
  };

  useEffect(() => {
    if (studies.length > 6) {
      setHasMore(studies.length - 6);
    }
  }, []);
  return (
    <>
      <div className="studies-table-wrapper">
        <div className="studies-table">
          <Row gutter={[24, 24]} className="studies-table-header">
            <Col span={7} className="studies-table-header-col">
              Indication
            </Col>
            <Col span={7} className="studies-table-header-col">
              Phase
            </Col>
            <Col span={3} className="studies-table-header-col">
              Number Enrolled
            </Col>
            <Col span={7} className="studies-table-header-col">
              Start Year
            </Col>
          </Row>
          <Col className="studies-table-body">
            {studies.slice(0, limit).map((study) => {
              return (
                <Row
                  gutter={[24, 24]}
                  className="studies-table-body-row"
                  key={study.studyId}
                >
                  <Col span={7} className="study-col">
                    {study.indication}
                  </Col>
                  <Col span={7} className="study-col">
                    {study.phase ? study.phase : "-"}
                  </Col>
                  <Col span={3} className="study-col">
                    {study.numberEnrolled} Subjects
                  </Col>
                  <Col span={7} className="study-col">
                    {study.startYear}
                  </Col>
                </Row>
              );
            })}
          </Col>
        </div>
      </div>
      {hasMore > 0 ? (
        <div className="hasmore-button">
          <span onClick={loadMore}>
            Show More <p>{hasMore}</p>
          </span>
        </div>
      ) : limit > 6 ? (
        <div className="hasmore-button">
          <span onClick={loadLess}>Show less</span>
        </div>
      ) : null}
    </>
  );
};

export default StudiesTable;
