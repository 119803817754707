import { useEffect, useState } from "react";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { notification } from "antd";
import OrganizationService from "../services/organizationService";
import UpdateLogoModal from "./UpdateLogoModal";

/**
 * A component that displays a carousel of images for a given site, allowing the user to upload, edit, and delete images.
 * @param {{Object}} siteData - The data for the site being displayed.
 * @param {{Function}} setImgCount - A function to update the count of images for the site.
 * @returns A React component that displays a carousel of images for the given site.
 */
const SiteImagesUpload = ({ siteData, setImgCount }) => {
  const [style, setStyle] = useState({ display: "none" });
  const [siteImages, setSiteImages] = useState([]);
  const [box, setBox] = useState();
  const [viewId, setViewId] = useState(-1);

  // Scrolls the container element to the left by 320 pixels.
  const btnPrev = () => {
    box.scrollLeft = box.scrollLeft - 320;
  };
  // Scrolls the content of a container element to the right by 320 pixels./
  const btnNext = () => {
    box.scrollLeft = box.scrollLeft + 320;
  };

  /**
   * Uploads an image to the server and updates the state of the component with the new image data.
   * @param {{fmData}} fmData - The image data to upload.
   * @returns A promise that resolves with the response from the server.
   */
  const uploadImage = async (fmData) => {
    return await OrganizationService.saveSiteImage(siteData.siteId, fmData)
      .then((res) => {
        OrganizationService.getSiteImages(siteData.siteId).then((res2) => {
          setSiteImages(res2.data.data.reverse());
          setImgCount(res2.data.data.length);
        });
      })
      .catch(() => {
        notification.error({ message: "Uploading Image failed !!!" });
      });
  };

  /**
   * Edits an image for a site by calling the OrganizationService.editSiteImage function.
   * @param {{fmData}} fmData - The image data to edit.
   */
  const editImage = async (fmData) => {
    return await OrganizationService.editSiteImage(
      siteData.siteId,
      viewId,
      fmData
    )
      .then((res) => {
        let tempImgs = [];
        siteImages.map((img) => {
          if (img.siteImageId !== viewId) {
            tempImgs.push(img);
          } else {
            tempImgs.push(res.data.data);
          }
        });
        setSiteImages(tempImgs);
        setImgCount(tempImgs.length);
      })
      .catch(() => {
        notification.error({ message: "Uploading Image failed !!!" });
      });
  };

  // Deletes an image from the site data and updates the state of the component.
  const deleteImage = async () => {
    return await OrganizationService.deleteSiteImage(siteData.siteId, viewId)
      .then((res) => {
        let tempImgs = [];
        siteImages.map((img) => {
          if (img.siteImageId !== viewId) {
            tempImgs.push(img);
          }
        });
        setSiteImages(tempImgs);
        setImgCount(tempImgs.length);
      })
      .catch(() => {
        notification.error({ message: "Deleting Image failed !!!" });
      });
  };

  /**
   * useEffect hook that fetches site images from the server and sets the state with the response data.
   * Also sets the box element to the element with class "list-no-" + siteData.siteId.
   * @param {{Object}} siteData - The site data object.
   */
  useEffect(() => {
    if (siteData) {
      OrganizationService.getSiteImages(siteData.siteId).then((res) => {
        setSiteImages(res.data.data.reverse());
        setImgCount(res.data.data.length);
      });
    }
    setBox(document.querySelector(".list-no-" + siteData.siteId));
  }, [siteData]);

  return (
    <div className="site-images-carousel-wrapper">
      <div className="site-carousel-prev-button">
        <FontAwesomeIcon
          icon={faChevronLeft}
          className="collection-icon"
          onClick={btnPrev}
        />
      </div>
      <div className="upload-carousel-1">
        {siteImages.length < 50 ? (
          <div
            className="site-images-upload-carousel-Button"
            onMouseEnter={() => {
              setStyle({
                display: "flex",
                height: "inherit",
                width: "inherit",
                color: "white",
              });
            }}
            onMouseLeave={() => {
              setStyle({ display: "none" });
            }}
          >
            + Upload
            <UpdateLogoModal
              style={style}
              setStyle={setStyle}
              uploadLogoData={uploadImage}
              titleText={"Image"}
              // deleteImage={deleteSiteLogo}
              deleteOption={false}
            />
          </div>
        ) : null}
      </div>
      <div className={"sites-carousel-container list-no-" + siteData.siteId}>
        <div className={"list-sites-carousel"}>
          {siteImages.map((img) => {
            return (
              <div
                className="site-carousel-image"
                key={img.siteImageId}
                onMouseEnter={() => {
                  setViewId(img.siteImageId);
                }}
                onMouseLeave={() => {
                  setViewId(-1);
                }}
              >
                <img src={img.signedUrl} alt="" />
                <UpdateLogoModal
                  style={
                    viewId === img.siteImageId
                      ? {
                          display: "flex",
                          color: "white",
                          height: "inherit",
                          width: "inherit",
                        }
                      : { display: "none" }
                  }
                  setStyle={setStyle}
                  uploadLogoData={editImage}
                  titleText={"Image"}
                  deleteImage={deleteImage}
                  deleteOption={true}
                  img={img.signedUrl}
                />
              </div>
            );
          })}
        </div>
      </div>
      <div className="site-carousel-next-button">
        <FontAwesomeIcon
          icon={faChevronRight}
          className="collection-icon"
          onClick={btnNext}
        />
      </div>
    </div>
  );
};

export default SiteImagesUpload;
