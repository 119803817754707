import { Col, Divider, Form, Input, Row } from "antd";
function InquiryInformation({}) {
  return (
    <>
      <Row style={{ marginBottom: "10px" }}>
        <Divider className="horizontal-divider-class" />
      </Row>
      <Row gutter={[24, 24]} style={{ marginBottom: "10px" }}>
        <Col xs={24} sm={24} md={10} lg={10} xl={10}>
          <div className="op-right-header">Inquiry Information</div>
        </Col>
      </Row>
      <Row gutter={[24, 24]} style={{ marginBottom: "10px" }}>
        <Col xs={24} sm={24} md={10} lg={10} xl={10}>
          <div className="op-label">
            * Primary Email Address
            <p className="op-p" style={{ margin: "5px 17px" }}>
              * Email for CRIO to send inquiries
            </p>
          </div>
        </Col>
        <Col xs={24} sm={24} md={14} lg={14} xl={14}>
          <Form.Item
            name="primaryEmail"
            rules={[
              {
                required: true,
                message: "Primary Email Address cannot be empty",
              },
              {
                type: "email",
                message: "Please provide a properly formatted email address",
              },
            ]}>
            <Input
              className="crio-input"
              placeholder="Enter Primary Email Address"
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
}

export default InquiryInformation;
