import axios from "axios";

/**
 * Creates an instance of Axios with the base URL set to the value of the REACT_APP_BASE_URL
 * environment variable.
 */
const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

//Interceptor function that adds the idToken and orgId to the request params if the URL does not include "/login".
instance.interceptors.request.use(function (config) {
  if (!config.url.includes("/login")) {
    const data = localStorage.getItem("SDIR_STARTER_DATA")
      ? JSON.parse(localStorage.getItem("SDIR_STARTER_DATA"))
      : "";
    if (data && data.token) {
      config.params = {
        ...config.params,
        idToken: data.token,
        orgId: data.organizationId,
      };
    }
  }
  return config;
});

/**
 * Intercepts the response from an HTTP request and returns the response if successful,
 * or rejects the promise with an error if unsuccessful.
 */
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.code === "Unauthorized Access" || error.response.status === 401) {
      localStorage.removeItem("SDIR_STARTER_DATA");
      document.cookie =
        "siteDirectory-jwt=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      window.location.href = "/sessionTimeout";
    }
    return Promise.reject(error);
  }
);

export default instance;
