import { createSlice } from "@reduxjs/toolkit";

// A Redux slice that manages the state of the application's states.
export const statesSlice = createSlice({
  name: "states",
  initialState: [],
  reducers: {
    saveStates: (state, action) => {
      return action.payload;
    },
    removeStates: (state, action) => {
      return [];
    },
  },
});

export const { saveStates, removeStates } = statesSlice.actions;
export default statesSlice.reducer;
