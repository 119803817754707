import { Button, Checkbox, Col, Divider } from "antd";
import { faQuestionCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
function Sidebar({
  organizationData,
  setOpen,
  premiumChecked,
  setPremiumChecked,
  acceptPremium,
}) {
  return (
    <>
      <Col xs={24} sm={24} md={5} lg={5} xl={5}>
        <div className="op">
          <div className="op-left-title">Organization Profile</div>
          <div className="op-p">
            The prefilled fields have all been imported from CRIO. Should any
            changes need to be made, please update the information accordingly.
          </div>
          <br />
          <br />
          <div className="op-p">
            **The changes you make in CRIO Connect will not affect your
            information in the CRIO site app.
          </div>
          <Divider dashed style={{ margin: "50px 0", borderColor: "#CCC" }} />
          <div className="org-premium-title">
            {organizationData.serviceOpted
              ? "You have successfully opted in to CRIO’s premium search"
              : "More ways to find connections"}
            <span
              onClick={() => {
                setOpen(true);
              }}>
              <FontAwesomeIcon
                icon={faQuestionCircle}
                fontSize={22}
                color="#009ceb"
              />
            </span>
          </div>
          {organizationData.serviceOpted ? (
            <div className="premium-p">
              If you want to opt out of the premium search, please contact CRIO.
            </div>
          ) : (
            <>
              <Checkbox
                checked={premiumChecked}
                onChange={(e) => {
                  setPremiumChecked(e.target.checked);
                }}
                className="agreement-footer-text">
                Yes, I would like to opt in for CRIO’s premium search. By opting
                in, you will let CRIO use your data for referral to
                Sponsors/CROs.
              </Checkbox>
              <Button
                type="primary"
                disabled={!premiumChecked}
                className="agreement-footer-button  agreement-footer-button-org"
                onClick={acceptPremium}>
                Opt in
              </Button>
            </>
          )}
        </div>
      </Col>
      <Col xs={0} sm={0} md={2} lg={2} xl={2} style={{ textAlign: "center" }}>
        <Divider type="vertical" className="vertical-divider-class" />
      </Col>
    </>
  );
}

export default Sidebar;
