import { createSlice } from "@reduxjs/toolkit";

// This slice of the Redux store manages the facility data.
export const facilitySlice = createSlice({
  name: "facility",
  initialState: [],
  reducers: {
    saveFacility: (state, action) => {
      return action.payload;
    },
    removeFacility: (state, action) => {
      return [];
    },
  },
});

export const { saveFacility, removeFacility } = facilitySlice.actions;
export default facilitySlice.reducer;
