import React, { useState } from "react";
import { faPen, faTrash } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Input, Modal, Row, Upload } from "antd";
import util from "../../utils/util";
import DefaultSizeLogo from "../../assets/images/dummy-logo.png";
import DeleteConfirmModal from "./DeleteConfirmModal";

/**
 * A modal component that allows the user to update a logo image. The component
 * includes a form for uploading a new image, as well as options for deleting the
 * current image. The component also displays information about the requirements
 * for the uploaded image.
 */
function UpdateLogoModal({
  style,
  setStyle,
  uploadLogoData,
  titleText,
  deleteImage,
  deleteOption,
  img,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [logo, setLogo] = useState();
  const [errStatus, setErrStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);

  /**
   * Changes the logo to the given file and updates the error status if necessary.
   * @param {{values}} values - An object containing the file to be used as the new logo.
   */
  const changeLogo = (values) => {
    let status = util.checkLogoError(values.file);
    if (status === null) {
      setLogo(values.file);
      setErrStatus("");
    } else {
      setErrStatus(status);
    }
  };

  /**
   * Uploads a logo to the server using a FormData object and the `uploadLogoData` function.
   * Sets the loading state to true while the logo is being uploaded and sets it back to false
   * once the upload is complete. Calls the `handleCancel` function to close the modal.
   */
  const uploadLogo = async () => {
    setLoading(true);
    const fmData = new FormData();
    fmData.append("logo", logo);
    await uploadLogoData(fmData);
    setLoading(false);
    handleCancel();
  };

  // Sets the state of the modal to open, displaying the modal on the page.
  const showModal = () => {
    setIsModalOpen(true);
  };

  // Handles the "Ok" button click event by calling the uploadLogo function.
  const handleOk = () => {
    uploadLogo();
  };

  // Resets the logo state to null and closes the modal.
  const handleCancel = () => {
    setLogo(null);
    setIsModalOpen(false);
  };

  // Closes the delete modal by setting the deleteOpen state to false.
  const exitDelete = () => {
    setDeleteOpen(false);
  };

  // Deletes an image and sets the loading state to true while the image is being deleted.
  const deleteLogo = async () => {
    setLoading(true);
    await deleteImage();
    setDeleteOpen(false);
    setLoading(false);
  };

  return (
    <>
      <DeleteConfirmModal
        open={deleteOpen}
        textAbout={titleText}
        exitDelete={exitDelete}
        deleteLogo={deleteLogo}
        loading={loading}
      />
      <div
        className="edit-logo-wrapper"
        style={style}
        onMouseLeave={() => {
          setStyle({ display: "none" });
        }}
      >
        <FontAwesomeIcon
          icon={faPen}
          className={"edit-logo"}
          onClick={showModal}
        />
        {deleteOption ? (
          <>
            <div className="devider-logo-div" />
            <FontAwesomeIcon
              icon={faTrash}
              className={"edit-logo"}
              onClick={() => setDeleteOpen(true)}
            />
          </>
        ) : null}
      </div>
      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        closeIcon={loading}
        maskClosable={!loading}
        title={<div className="logo-update-title">Update {titleText}</div>}
        style={{ minWidth: "650px" }}
        centered
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={handleOk}
            className="logo-modal-ok"
            loading={loading}
            style={
              (logo ? (logo.name === "" ? true : false) : true)
                ? {}
                : { backgroundColor: "#4e6d8b" }
            }
            disabled={logo ? (logo.name === "" ? true : false) : true}
          >
            Save Changes
          </Button>,
          <Button
            key="back"
            onClick={handleCancel}
            disabled={loading}
            className="logo-modal-cancel"
          >
            Cancel
          </Button>,
        ]}
      >
        <p className="logo-update-description" style={{ color: "red" }}>
          {errStatus}
        </p>
        <p className="logo-update-description">
          Upload a new {titleText} below
        </p>
        <Row gutter={[24, 24]}>
          <Col xs={24} sm={24} md={10} lg={10} xl={10}>
            <div className="logo-update-img-wrapper">
              {img ? (
                <img src={img} className="logo-update-img" />
              ) : (
                <img src={DefaultSizeLogo} className="logo-update-img" />
              )}
            </div>
          </Col>
          <Col xs={24} sm={24} md={14} lg={14} xl={14}>
            <div className="logo-update-right">
              <h6>Upload requirements: </h6>
              <ul>
                <li>
                  <span>Filesize:</span> 10MB max{" "}
                </li>
                <li>
                  <span>Dimensions:</span> minimum size should be 160px W 80px.
                  Keep 2:1 aspect ratio
                </li>
                <li>
                  <span>Format:</span> png, jpeg, or bmp
                </li>
              </ul>
            </div>
          </Col>
        </Row>
        <Input.Group compact className="logo-modal-input-group">
          <Input
            prefix={
              <Upload
                customRequest={changeLogo}
                beforeUpload={false}
                maxCount={1}
                accept={"image/png, image/jpeg, image/bmp"}
                showUploadList={false}
                disabled={loading}
              >
                <Button className={"modalUploadButton"} disabled={loading}>
                  Choose File
                </Button>
              </Upload>
            }
            className="logo-modal-input"
            value={logo ? logo.name : null}
            placeholder="No file chosen"
          />
          <Button
            type="primary"
            style={{ height: "40px" }}
            disabled={logo ? (logo.name === "" ? true : false) : true}
            onClick={uploadLogo}
            loading={loading}
            className="logo-modal-upload"
          >
            Upload
          </Button>
        </Input.Group>
      </Modal>
    </>
  );
}

export default UpdateLogoModal;
