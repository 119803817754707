import util from "../../../../../utils/util";
import { Checkbox, Form } from "antd";
function UniformLogoField({ confirm, logo }) {
  return (
    <Form.Item name={"uniformLogo"} valuePropName="checked">
      <Checkbox
        className="op-checkbox"
        checked={true}
        disabled={util.isEmptyStr(logo)}
        onChange={(e) => {
          confirm("logo", e);
        }}>
        Apply this logo to all sites
      </Checkbox>
    </Form.Item>
  );
}

export default UniformLogoField;
