import axios from "../../config/axiosConfig";
import { login } from "../../constants/urls";

const LoginService = {
  /**
   * Logs in the user with the given orgId and idToken.
   * @param {string} orgId - The organization ID of the user.
   * @param {string} idToken - The ID token of the user.
   * @returns A promise that resolves with the login response.
   */
  login: (orgId, idToken) => {
    var config = {
      method: "post",
      url: login + orgId + "/login",
      params: {
        idToken: idToken,
      },
      headers: {
        "Content-Type": "application/json",
      },
    };
    return axios(config);
  },
  /**
   * Logs the user out of the application by removing the user's data from local storage,
   * deleting the JWT cookie, and redirecting the user to the session timeout page.
   */
  logout: () => {
    localStorage.removeItem("SDIR_STARTER_DATA");
    document.cookie =
      "siteDirectory-jwt=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    window.location.href = process.env.REACT_APP_CRIO_LOGOUT_URL;
  },
};

export default LoginService;
