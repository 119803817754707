export const dropdownData = [
  {
    key: "1",
    label: "5",
    value: "5",
  },
  {
    key: "2",
    label: "10",
    value: "10",
  },
  {
    key: "3",
    label: "20",
    value: "20",
  },
  {
    key: "4",
    label: "50",
    value: "50",
  },
];
