import UpdateLogoModal from "../../../../components/UpdateLogoModal";
import { useState } from "react";
import OrganizationService from "../../../../services/organizationService";
import { saveOrganization } from "../../../../features/organization";
import { useDispatch } from "react-redux";
import { notification, Skeleton } from "antd";
function LogoField({ logo, organizationData }) {
  const [style, setStyle] = useState({ display: "none" });
  const dispatch = useDispatch();

  // Uploads an image to the server and updates the organization logo in the store.
  const uploadImage = async (fmData) => {
    return await OrganizationService.uploadLogo(fmData)
      .then((res) => {
        let refreshData = Object.assign({}, res.data.data);
        refreshData.organizationLogo = null;
        dispatch(saveOrganization(refreshData));
        dispatch(saveOrganization(res.data.data));
      })
      .catch(() => {
        notification.error({
          message: "Sorry, there was a problem uploading the logo",
        });
      });
  };

  /**
   * Deletes the logo of the organization by calling the deleteLogo method of the OrganizationService.
   * If the deletion is successful, the organizationData is updated to remove the logo and the updated data is saved.
   * If the deletion fails, a notification is displayed.
   */
  const deleteImage = async () => {
    return await OrganizationService.deleteLogo()
      .then((res) => {
        let refreshData = Object.assign({}, organizationData);
        refreshData.organizationLogo = null;
        dispatch(saveOrganization(refreshData));
      })
      .catch((error) => {
        notification.error({
          message: "Sorry, there was a problem deleting the logo",
        });
      });
  };

  return (
    <div className="op-logo">
      {logo ? (
        <img
          src={logo}
          alt="Organization Logo"
          onMouseEnter={() => {
            setStyle({
              display: "flex",
              margin: "20px 10px",
              height: "-webkit-fill-available",
              width: "-webkit-fill-available",
              color: "white",
            });
          }}
        />
      ) : (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onMouseEnter={() => {
            setStyle({
              display: "flex",
              margin: "20px 10px",
              height: "-webkit-fill-available",
              width: "-webkit-fill-available",
              color: "white",
            });
          }}>
          <Skeleton.Image />
        </div>
      )}
      <UpdateLogoModal
        style={style}
        setStyle={setStyle}
        uploadLogoData={uploadImage}
        titleText={"Logo"}
        deleteImage={deleteImage}
        deleteOption={organizationData.organizationLogo ? true : false}
        img={organizationData.organizationLogo ? logo : false}
      />
    </div>
  );
}

export default LogoField;
