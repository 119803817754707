import React from "react";
import { useSelector } from "react-redux";
import { faUserCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row } from "antd";

/**
 * A functional component that displays the user profile of an admin user.
 * @returns A JSX element that displays the user profile.
 */
function UserProfile() {
  const admin = useSelector((state) => state.admin);

  return (
    <div className="user-profile">
      <Row>
        <div className="user-profile-left">User Profile</div>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={6} lg={4} xl={4}>
          <div className="user-profile-left">
            <div className="user-profile-left-img">
              {admin.profilePic ? (
                <img src={admin.profilePic} alt="Profile" />
              ) : (
                <FontAwesomeIcon
                  icon={faUserCircle}
                  style={{ width: "100%", height: "100%" }}
                />
              )}
            </div>
          </div>
        </Col>
        <Col xs={24} sm={24} md={18} lg={20} xl={20}>
          <div className="user-profile-right">
            <Row>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Row>
                  <Col span={10} className="user-profile-right-text">
                    First Name
                  </Col>
                  <Col span={14} className="user-profile-right-text">
                    {admin && admin.firstName ? admin.firstName : "--"}
                  </Col>
                </Row>
                <Row>
                  <Col span={10} className="user-profile-right-text">
                    Last Name
                  </Col>
                  <Col span={14} className="user-profile-right-text">
                    {admin && admin.lastName ? admin.lastName : "--"}
                  </Col>
                </Row>
                <Row>
                  <Col span={10} className="user-profile-right-text">
                    Middle Name
                  </Col>
                  <Col span={14} className="user-profile-right-text">
                    {admin && admin.middleName ? admin.middleName : "--"}
                  </Col>
                </Row>
                <Row>
                  <Col span={10} className="user-profile-right-text">
                    Credentials
                  </Col>
                  <Col span={14} className="user-profile-right-text">
                    --
                  </Col>
                </Row>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Row>
                  <Col span={10} className="user-profile-right-text">
                    Email Address
                  </Col>
                  <Col span={14} className="user-profile-right-text">
                    {admin && admin.email ? admin.email : "--"}
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default UserProfile;
