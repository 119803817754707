import AgreementImage from "../../../../assets/images/iStock-1390529500.jpg";
import { Col, Modal, Row } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons";
function PremiumModal({ open, setOpen }) {
  return (
    <Modal
      open={open}
      footer={null}
      onCancel={() => {
        setOpen(false);
      }}
      style={{
        top: 40,
      }}
      width={700}>
      <div className="agreement-header">More ways to find connections</div>
      <div className="agreement-body">
        <div className="agreement-body-text-modal">
          <div className="agreement-img">
            <img src={AgreementImage} alt="AgreementImage" />
          </div>
          <div
            // dangerouslySetInnerHTML={{ __html: terms }}
            className="premium-body-text">
            CRIO may be asked by sponsors or CROs to conduct premium searches
            using access to information within our system that is not available
            on CRIO Connect. <span>Do you give CRIO permission</span>
            <i>
              {" "}
              to mine your data for purposes of recommending sites on these
              premium searches?
            </i>
            <br />
            <br />
            <Row>
              <Col span={2}>
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  color="#138639"
                  fontSize={22}
                />
              </Col>
              <Col span={22}>
                CRIO will never share your CRIO data directly with sponsors or
                CROs; instead, CRIO will indicate whether the premium search
                yielded likely compatibility against pre-specified target
                criteria (e.g., experience with a certain class of
                investigational drug, or exposure to a certain scale).
              </Col>
            </Row>
            <br />
            <Row>
              <Col span={2}>
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  color="#138639"
                  fontSize={22}
                />
              </Col>
              <Col span={22}>
                CRIO recognizes that data within your CRIO system may not be
                complete, so CRIO will never disqualify a site based on this
                premium search, nor will CRIO disclose to the sponsor or CRO
                which specific sites CRIO data mined. CRIO will only be
                providing names of sites who appear to meet the target criteria
                and will caveat that the list is suggestive and not
                comprehensive. Furthermore, CRIO will never use access to your
                data to qualify or disqualify a statement you have made to a
                sponsor or CRO.
              </Col>
            </Row>
            <br />
            <Row>
              <Col span={2}>
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  color="#138639"
                  fontSize={22}
                />
              </Col>
              <Col span={22}>
                Accordingly, there is very little risk to opting in, and there
                is the potential to be proactively advanced by CRIO for
                consideration on a given study.
              </Col>
            </Row>
            <br />
            <br />
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default PremiumModal;
