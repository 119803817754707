import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import PublicUnpublishedFilterTable from "../../components/PublicUnpublishedFilterTable";
import SideDataTable from "../../components/SideDataTable";
import OrganizationService from "../../services/organizationService";

/**
 * A functional component that displays a table of organization sites and allows the user to filter
 * the sites by public/unpublished status and search for specific sites.
 * @returns A JSX element that displays the site table and filters.
 */
const Site = () => {
  const [tableData, setTableData] = useState([]);
  const [siteCount, setSiteCount] = useState(0);
  const [publicCheck, setPublicCheck] = useState(false);
  const [unpublishedCheck, setUnpublishedCheck] = useState(false);
  const [search, setSearch] = useState();
  const [sites, setSites] = useState();
  const [loading, setLoading] = useState(false);

  // Updates the search state and calls the onChangeFilter function with the updated search data.
  const changeSearch = (searchData) => {
    setSearch(searchData);
    onChangeFilter(publicCheck, unpublishedCheck, tableData, searchData);
  };
  // Filters the given data based on the search criteria and sets the filtered data as the new state.
  const searchFilter = (searchData, data) => {
    let tempSites = [];
    if (!searchData || searchData === "") {
      setSites(data);
    } else {
      data.forEach((site) => {
        if (
          site.siteName.toLowerCase().includes(searchData.toLowerCase()) ||
          site.organizationName.toLowerCase().includes(searchData.toLowerCase())
        ) {
          tempSites.push(site);
        }
      });
      setSites(tempSites);
    }
  };
  /**
   * Clears the current selection by resetting the public and unpublished check states to false
   * and applying the current search filter to the table data.
   */
  const clearSelection = () => {
    setPublicCheck(false);
    setUnpublishedCheck(false);
    searchFilter(search, tableData);
  };

  // Updates the state of the public check and calls the onChangeFilter function with the updated data.
  const changePublic = (data) => {
    setPublicCheck(data);
    onChangeFilter(data, unpublishedCheck, tableData, search);
  };

  // Updates the state of the unpublished check and calls the onChangeFilter function with the updated state.
  const changeUnpublished = (data) => {
    setUnpublishedCheck(data);
    onChangeFilter(publicCheck, data, tableData, search);
  };

  /**
   * Filters the given data array based on the provided parameters and calls the searchFilter function.
   * @param {{boolean}} pc - A boolean indicating whether to include published sites.
   * @param {{boolean}} unPublished - A boolean indicating whether to include unpublished sites.
   * @param {{Array}} data - An array of site objects to filter.
   * @param {{Object}} searchData - An object containing search parameters.
   */
  const onChangeFilter = (pc, unPublished, data, searchData) => {
    let tempSites = [];
    data.forEach((site) => {
      if (pc && site.isActive) {
        tempSites.push(site);
      } else if (unPublished && !site.isActive) {
        tempSites.push(site);
      } else if ((!unPublished && !pc) || (unPublished && pc)) {
        tempSites.push(site);
      }
    });
    searchFilter(searchData, tempSites);
  };

  // useEffect hook that fetches all organization sites and sets the table data and site count state.
  useEffect(() => {
    setLoading(true);
    OrganizationService.getAllOrgSites()
      .then((res) => {
        setTableData(res.data.data.organizationSitesDatas);
        setSiteCount(res.data.data.sitesCount);
        setLoading(false);
        onChangeFilter(
          publicCheck,
          unpublishedCheck,
          res.data.data.organizationSitesDatas,
          search
        );
      })
      .catch((err) => {
        setLoading(false);
      });
    setSiteCount(tableData.length);
  }, []);
  return (
    <div className="body-container" style={{ minHeight: "70vh" }}>
      <div className="sites-header">
        Sites<p>{siteCount}</p>
      </div>
      <Row gutter={[24, 24]}>
        <Col xs={24} sm={24} md={5} lg={5} xl={5}>
          <div className="sites-left">
            <PublicUnpublishedFilterTable
              publicCheck={publicCheck}
              setPublicCheck={changePublic}
              unpublishedCheck={unpublishedCheck}
              setUnpublishedCheck={changeUnpublished}
              clearSelection={clearSelection}
            />
          </div>
        </Col>
        <Col xs={24} sm={24} md={19} lg={19} xl={19}>
          <div className="sites-left">
            <SideDataTable
              sites={sites}
              setSearch={changeSearch}
              loading={loading}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Site;
