import React from "react";
import { Link } from "react-router-dom";

/**
 * A functional component that renders a 404 Not Found page with a link to the home page.
 * @returns A JSX element that displays the 404 Not Found page.
 */
const NotFoundPage = () => {
  const homeUrl = "/";
  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="error-template">
              <h1>Oops!</h1>
              <h2>404 Not Found</h2>
              <div className="error-details">
                Sorry, an error has occurred, Requested page not found!
              </div>
              <div className="error-actions">
                <Link exact to={homeUrl} className="btn btn-primary btn-lg">
                  <span className="bi bi-house"></span>
                  Take Me Home{" "}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
