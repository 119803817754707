import React from "react";
import { Button, Col, Modal, Row } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/pro-solid-svg-icons";
/**
 * A modal component that displays a warning message and allows the user to confirm or cancel
 * a delete action for images.
 */
const DeleteConfirmModal = ({
  open,
  textAbout,
  deleteLogo,
  exitDelete,
  loading,
}) => {
  return (
    <>
      <Modal
        open={open}
        footer={null}
        centered
        style={{ maxWidth: "550px" }}
        onCancel={() => exitDelete()}
        maskClosable={false}>
        <Row>
          <Col span={3}>
            <FontAwesomeIcon
              icon={faExclamationCircle}
              color={"#c04040"}
              fontSize={50}
            />
          </Col>
          <Col span={18}>
            <div className="warning-right-header">Delete {textAbout}</div>
            <div className="warning-right-body">
              Once deleted {textAbout}, Cannot be restored.
            </div>
            <div className="warning-footer">
              <Button
                className="warning-footer-left"
                onClick={() => exitDelete()}>
                Cancel
              </Button>
              <Button
                className="warning-footer-right"
                onClick={() => deleteLogo()}
                loading={loading}>
                Delete
              </Button>
            </div>
          </Col>
        </Row>
      </Modal>
    </>
  );
};
export default DeleteConfirmModal;
