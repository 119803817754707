import { Col, Row } from "antd";
function OrganizationNameField({ organizationData }) {
  return (
    <Row gutter={[24, 24]} style={{ marginBottom: "20px" }}>
      <Col xs={24} sm={24} md={10} lg={10} xl={10}>
        <div className="op-label">Organization Name</div>
      </Col>
      <Col xs={24} sm={24} md={14} lg={14} xl={14}>
        <div className="op-label">{organizationData.organizationName}</div>
      </Col>
    </Row>
  );
}

export default OrganizationNameField;
