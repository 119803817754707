import util from "utils/util";
import { useState } from "react";
import { Col, Checkbox, Form, Input, Row } from "antd";

function DescriptionField({
  organizationDescription,
  confirm,
  wordCount,
  setWordCount,
}) {
  const handleTextAreaChange = (e) => {
    const { value } = e.target;
    let words = [];
    if (value !== "") {
      words = value.trim().split(/\s+/);
    }
    if (words.length === 200 && value.charAt(value.length - 1) === " ") {
      setWordCount(words.length + 1);
    } else {
      setWordCount(words.length);
    }
  };

  const validateWordLimit = (_, value) => {
    if (
      value &&
      value.trim().split(/\s+/).length === 200 &&
      value.charAt(value.length - 1) === " "
    ) {
      return Promise.reject("Maximum word limit exceeded (200 words).");
    }
    if (value && value.trim().split(/\s+/).length > 200) {
      return Promise.reject("Maximum word limit exceeded (200 words).");
    }
    return Promise.resolve();
  };

  return (
    <Row gutter={[24, 24]} style={{ marginBottom: "20px" }}>
      <Col xs={24} sm={24} md={10} lg={10} xl={10}>
        <div className="op-label">Organization Description</div>
      </Col>
      <Col xs={24} sm={24} md={14} lg={14} xl={14}>
        <Form.Item
          name={"organizationDescription"}
          rules={[
            {
              validator: validateWordLimit,
            },
          ]}>
          <Input.TextArea
            bordered={false}
            onChange={handleTextAreaChange}
            className="crio-input"
            style={{
              height: 120,
              resize: "none",
              fontSize: "14px",
              fontFamily: ["Poppins-Light", "Poppins Light", "Poppins"],
              fontWeight: "200",
              color: "#999999 !important",
            }}
            placeholder="Enter Description (Max 200 words)"
          />
        </Form.Item>
        <div className="crio-word-count">{wordCount} / 200</div>
        <Form.Item name={"uniformDescription"} valuePropName="checked">
          <Checkbox
            className="op-checkbox"
            disabled={util.isEmptyStr(organizationDescription)}
            onChange={(e) => {
              confirm("description", e);
            }}>
            Apply this description to all sites
          </Checkbox>
        </Form.Item>
      </Col>
    </Row>
  );
}

export default DescriptionField;
