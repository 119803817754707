import React, { useState } from "react";
import { faBuilding } from "@fortawesome/pro-regular-svg-icons";
import {
  faChevronDown,
  faChevronUp,
  faEye,
  faUserCircle,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Divider, Row, Skeleton, Space, Tooltip } from "antd";

/**
 * A component that displays information about a site, including its name, location, therapeutic areas, and principal investigators.
 * @param {{siteData}} siteData - An object containing data about the site to be displayed.
 * @returns A React component that displays the site information.
 */
function SiteCard({ siteData }) {
  const piCount = siteData.numOfPi;
  const [sliceCount, setSliceCount] = useState(4);
  return (
    <div
      className="dashboard-site-card"
      style={{
        opacity: siteData.isActive ? "1" : "0.6",
      }}
    >
      {siteData.isActive ? (
        <div className="dashboard-site-card-header-cta">
          <Tooltip
            title={"Click to Preview"}
            placement="bottomRight"
            arrowPointAtCenter
            overlayInnerStyle={{
              color: "#666666",
              backgroundColor: "#fff",
              padding: "20px 30px",
              fontSize: "16px",
            }}
            overlayStyle={{
              backgroundColor: "#fff",
              "--antd-arrow-background-color": "#fff",
            }}
          >
            <FontAwesomeIcon
              icon={faEye}
              onClick={() => {
                window.open("/preview/" + siteData.siteId, "_blank");
              }}
            />
          </Tooltip>
        </div>
      ) : null}
      <Row
        gutter={24}
        style={{ cursor: "pointer" }}
        onClick={(e) => {
          window.open("/site/" + siteData.siteId, "_blank");
        }}
      >
        <Col xs={24} sm={24} md={4} lg={4} xl={4}>
          <Row>
            <div
              className={
                siteData && siteData.isActive
                  ? "dashboard-site-is-active-true"
                  : "dashboard-site-is-active-false"
              }
            />
          </Row>
          <Row>
            <div className="dashboard-site-card-img">
              {siteData.siteLogo ? (
                <img src={siteData.siteLogo} alt="SITE LOGO" />
              ) : (
                <Skeleton.Image />
              )}
            </div>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={20} lg={20} xl={20}>
          <Row style={{ marginBottom: "10px" }}>
            <div className="dashboard-site-card-header">
              <div className="dashboard-site-card-header-name">
                <FontAwesomeIcon icon={faBuilding} />
                <span>{siteData.siteName}</span>
              </div>
            </div>
          </Row>
          <Row>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={8}
              xl={6}
              className="dashboard-site-details-tags"
            >
              <p>
                {(siteData.city ? siteData.city : "NA") +
                  ", " +
                  (siteData.country ? siteData.country : "NA")}{" "}
              </p>
              <span>Locations</span>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={11}
              xl={9}
              className="dashboard-site-details-tags"
            >
              {siteData.therapeuticList.length > 0 ? (
                <Tooltip
                  title={siteData.therapeuticList
                    .toString()
                    .replaceAll(",", ", ")}
                  placement="bottomRight"
                  arrowPointAtCenter
                  overlayInnerStyle={{
                    color: "#666666",
                    backgroundColor: "#fff",
                    padding: "20px 20px",
                    fontSize: "16px",
                  }}
                  overlayStyle={{
                    backgroundColor: "#fff",
                    "--antd-arrow-background-color": "#fff",
                  }}
                >
                  {siteData.therapeuticList.length > 1 ? (
                    <div className="therapeutic-list-title">
                      <p style={{ maxWidth: "90px" }}>
                        {siteData.therapeuticList[0]}
                      </p>
                      <p>{siteData.therapeuticList.length - 1 + "+"}</p>
                    </div>
                  ) : (
                    <p>{siteData.therapeuticList.toString()}</p>
                  )}
                </Tooltip>
              ) : (
                <p>NA</p>
              )}{" "}
              <span>Therapeutic Areas</span>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={5}
              xl={5}
              className="dashboard-site-details-tags"
            >
              <p>{siteData.allTimeViewer} </p>
              <span>Overall View</span>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={4}
              xl={4}
              className="dashboard-site-details-tags"
            >
              <p>{siteData.todayViewer} </p>
              <span>New Views</span>
            </Col>
          </Row>
          <Row>
            <div className="dashboard-site-description">
              {siteData.siteDesciption}
            </div>
          </Row>
          <Row>
            <Divider dashed />
          </Row>
          <Row>
            <div className="dashboard-site-card-pi-header">
              Principal Investigators<span>{piCount}</span>
            </div>
          </Row>
          <Row style={{ marginTop: "20px" }}>
            {siteData.piDataResponseDtos &&
              siteData.piDataResponseDtos.slice(0, sliceCount).map((el) => {
                return (
                  <Col
                    xs={12}
                    sm={12}
                    md={6}
                    className="dashboard-site-card-pi-body"
                    key={el.piId}
                  >
                    <Space>
                      {el.profilePic ? (
                        <div className="dashboard-site-card-pi-body-img">
                          <img src={el.profilePic} alt="PI" />
                        </div>
                      ) : (
                        <FontAwesomeIcon
                          icon={faUserCircle}
                          fontSize={"23px"}
                          color={"#999999"}
                        />
                      )}
                      <div className="dashboard-site-card-pi-body-name">
                        {el.piName}
                      </div>
                    </Space>
                  </Col>
                );
              })}
          </Row>
        </Col>
      </Row>
      {piCount > 4 ? (
        <Row className="dashboard-see-more">
          {piCount > sliceCount ? (
            <FontAwesomeIcon
              className="dashboard-see-more-icon"
              icon={faChevronDown}
              onClick={() => {
                setSliceCount(piCount);
              }}
            />
          ) : (
            <FontAwesomeIcon
              className="dashboard-see-more-icon"
              icon={faChevronUp}
              onClick={() => {
                setSliceCount(4);
              }}
            />
          )}
        </Row>
      ) : null}
    </div>
  );
}

export default SiteCard;
