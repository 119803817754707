import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Button, Result, Skeleton, Space, Spin } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding } from "@fortawesome/pro-solid-svg-icons";
import StudiesTable from "../../components/StudiesTable";
import AboutSites from "../../components/AboutSites";
import SitePageService from "../../services/SitePageService";

/**
 * A functional component that displays information about a specific site, including its logo, name, organization name,
 * studies associated with the site, and additional information about the site.
 * @returns A JSX element that displays the site information.
 */
const SitePage = () => {
  let { id } = useParams();
  const history = useHistory();
  const [site, setSite] = useState();
  const [studyCount, setStudyCount] = useState(0);
  const [studies, setStudies] = useState();
  const [siteImages, setSiteImages] = useState([]);
  const [siteAvailable, setSiteAvailable] = useState(true);
  const [pi, setPi] = useState([]);

  /**
   * useEffect hook that runs when the component mounts. It sets the state of the component
   * with data fetched from the SitePageService API. It also scrolls the window to the top of the page.
   * @param {{string}} id - the id of the site to fetch data for
   */
  useEffect(() => {
    window.scrollTo(0, 0);
    if (id) {
      SitePageService.getSiteData(id)
        .then((res) => {
          setSite(res.data.data);
        })
        .catch((err) => {
          setSiteAvailable(false);
        });
      SitePageService.getStudies(id).then((res) => {
        setStudies(res.data.data);
        setStudyCount(res.data.data.length);
      });
      SitePageService.getImages(id)
        .then((res) => {
          setSiteImages(res.data.data);
        })
        .catch((err) => {
          setSiteImages(false);
        });
      SitePageService.getSinglePi(id)
        .then((res) => {
          setPi(res.data.data);
        })
        .catch((err) => {
          setPi(false);
        });
    }
  }, []);
  return site ? (
    <div className="body-container">
      <div className="site-page-container">
        <div className="site-page-header preview-site-page-body">
          <div className="site-page-header-left">
            <div className="site-page-header-left-logo">
              {site && site.siteLogo ? (
                <img src={site.siteLogo} alt="Site Logo" />
              ) : (
                <Skeleton.Image className="site-skaleton-img" />
              )}
            </div>
            <div className="site-page-header-left-text">
              <Space size={18} className="site-page-header-left-text-name">
                <FontAwesomeIcon icon={faBuilding} />
                {site && site.siteName ? site.siteName : <Skeleton.Input />}
              </Space>
              <div className="site-page-header-left-text-org-name">
                {site && site.orgName ? site.orgName : <Skeleton.Input />}
              </div>
            </div>
          </div>
        </div>
        <div className="preview-site-page-body">
          <span>
            Studies<p>{studyCount}</p>
          </span>
          {studyCount === 0 ? null : studies ? (
            <StudiesTable studies={studies} />
          ) : (
            <Skeleton active />
          )}
          <span>About the Site</span>
          <AboutSites pi={pi} site={site} siteImages={siteImages} />
        </div>
      </div>
    </div>
  ) : siteAvailable ? (
    <Spin tip="Loading" size="large">
      <div className="site-page-loading-container" />
    </Spin>
  ) : (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={
        <Button
          type="primary"
          onClick={() => {
            history.goBack();
          }}
        >
          Go Back
        </Button>
      }
    />
  );
};

export default SitePage;
