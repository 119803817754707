import axios from "../../config/axiosConfig";
import { editSiteUrl, pIEdit, studyUrl } from "../../constants/urls";

const SitePageService = {
  /**
   * Sends a request to the edit site URL with the given site ID to retrieve site data.
   * @param {string} siteId - The ID of the site to retrieve data for.
   * @returns {Promise} A promise that resolves with the site data.
   */
  getSiteData: (siteId) => {
    return axios.post(editSiteUrl + siteId);
  },
  /**
   * Retrieves the site information for editing from the server.
   * @param {number} siteId - The ID of the site to retrieve information for.
   * @returns {Promise} A promise that resolves with the site information.
   */
  getSiteInfoForEdit: (siteId) => {
    return axios.get(editSiteUrl + siteId);
  },
  /**
   * Retrieves studies for a given site ID.
   * @param {string} siteId - The ID of the site to retrieve studies for.
   * @returns {Promise} A promise that resolves with the studies data.
   */
  getStudies: (siteId) => {
    return axios.post(studyUrl + "site/" + siteId);
  },
  /**
   * Retrieves the images for a given site ID using an Axios GET request.
   * @param {string} siteId - The ID of the site to retrieve images for.
   * @returns {Promise} A Promise that resolves with the retrieved images.
   */
  getImages: (siteId) => {
    return axios.get(editSiteUrl + siteId + "/images");
  },
  /**
   * Sends a POST request to the server to retrieve a single Pi object for the given site ID.
   * @param {string} siteId - The ID of the site to retrieve the Pi object for.
   * @returns {Promise} A Promise that resolves with the Pi object.
   */
  getSinglePi: (siteId) => {
    return axios.post(pIEdit + "site/" + siteId);
  },
  /**
   * Retrieves all sites for a given organization ID, with optional pagination and sorting.
   * @param {string} orgId - The ID of the organization to retrieve sites for.
   * @param {object} payload - The payload object to send with the request.
   * @param {number} pageSize - The number of sites to retrieve per page.
   * @param {number} pageNumber - The page number to retrieve.
   * @param {string} sortBy - The field to sort the results by.
   * @returns A Promise that resolves with the response data from the server.
   */
  getAllSites: (orgId, payload, pageSize, pageNumber, sortBy) => {
    var config = {
      method: "post",
      url: editSiteUrl + orgId + "/allSites",
      params: {
        pageSize: pageSize,
        pageNumber: pageNumber,
        sortBy: sortBy,
        sortDir: sortBy === "site_name" ? "asc" : "desc",
      },
      headers: {
        "Content-Type": "application/json",
      },
      data: payload,
    };
    return axios(config);
  },
  /**
   * Sends a PUT request to the server to switch the status of a site to activate / deactivate.
   * @param {string} siteId - The ID of the site to activate / deactivate.
   * @returns A Promise that resolves with the response data from the server.
   */
  switchStatusForSite: (siteId) => {
    return axios.put(editSiteUrl + siteId + "/activate");
  },
  /**
   * Sends a POST request to the server to switch the status of a PI with the given ID.
   * @param {number} piId - The ID of the PI to switch the status for.
   * @returns A Promise that resolves with the response data from the server.
   */
  switchStatusForPI: (piId) => {
    return axios.post(pIEdit + piId + "/status");
  },
  /**
   * Retrieves site information for percentage for a given site ID using an Axios GET request.
   * @param {string} siteId - The ID of the site to retrieve information for.
   * @returns {Promise} A Promise that resolves with the site information.
   */
  getSiteInfoForPer: (siteId) => {
    return axios.get(editSiteUrl + siteId);
  },
};

export default SitePageService;
