import { Button, Modal, Space } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/pro-solid-svg-icons";
import React from "react";

/* This hook is temporary until we can move these modals into their own components. We should only use `useModal` when we have a need for accessing React Context, otherwise we should create separate JSX components for modals and insert them where we need them */

const TEXTFIELD_TO_ORG_DATA_MAP = {
  "display name": "uniformDisplayName",
  logo: "uniformLogo",
  URL: "uniformUrl",
  description: "uniformDescription",
};

const useApplyAllModal = (form, organizationData) => {
  const [modal, contextHolder] = Modal.useModal();

  const uniformFields = (textField, value) => {
    form.setFields([
      {
        name: TEXTFIELD_TO_ORG_DATA_MAP[textField],
        value,
      },
    ]);
  };

  const confirm = (textField, e) => {
    let orgDataField = TEXTFIELD_TO_ORG_DATA_MAP[textField];
    let fieldUniformity = organizationData[orgDataField];
    let noExistingSites = true;

    // All this is saying is that if a field's value is uniform or partially uniform, then that means we have sites that are using the organization's data
    if (fieldUniformity !== "NONUNIFORM") noExistingSites = false;

    if (e.target.checked) {
      let confirmModal = modal.confirm({
        icon: (
          <FontAwesomeIcon
            icon={faExclamationCircle}
            color="#009ceb"
            fontSize={50}
          />
        ),
        title: (
          <div className="warning-right-header">
            {noExistingSites
              ? `You are about to apply this ${textField} to all sites`
              : `Some sites already have an existing ${textField}`}
          </div>
        ),
        content: (
          <>
            <div className="warning-right-body">
              {noExistingSites
                ? "This action can only be undone by editing sites individually"
                : `Are you sure you want to override any existing site ${textField}s?`}
            </div>
            <Space>
              <Button
                className="warning-footer-left"
                onClick={() => {
                  uniformFields(textField, 2);
                  confirmModal.destroy();
                }}>
                Apply to all sites
              </Button>
              {!noExistingSites && (
                <Button
                  className="warning-footer-left"
                  onClick={() => {
                    uniformFields(textField, 1);
                    confirmModal.destroy();
                  }}>
                  Apply to sites without {textField}
                </Button>
              )}
              {noExistingSites && (
                <Button
                  className="modal-button-secondary"
                  onClick={() => {
                    uniformFields(textField, false);
                    confirmModal.destroy();
                  }}>
                  Cancel
                </Button>
              )}
            </Space>
          </>
        ),
        footer: null,
        onCancel: () => {
          uniformFields(textField, false);
        },
        width: 600,
        centered: true,
        closable: true,
        okButtonProps: { style: { display: "none" } },
        cancelButtonProps: { style: { display: "none" } },
      });
    }
  };

  return [confirm, contextHolder];
};

export default useApplyAllModal;
