import { Col, Row, InputNumber, Divider, Form } from "antd";
import React, { useMemo, useState } from "react";

// Currently used for races, ethnicities, and sexes

function DiversityPopulation({ formName, form, labelName }) {
  const [hasValidationError, setHasValidationError] = useState(false);
  const formValues = Form.useWatch(formName, form) || [];

  const totalPercentage = useMemo(() => {
    let percentage = 0;

    formValues.forEach((diversityEntity) => {
      if (diversityEntity.percentage !== null)
        percentage += diversityEntity.percentage;
    });

    return percentage;
  }, [formValues]);

  const validatePercentage = (_, value) => {
    if (!value) {
      return Promise.resolve();
    }

    if (totalPercentage === 100) {
      setHasValidationError(false);
      return Promise.resolve();
    }

    if (totalPercentage > 100 || totalPercentage < 0) {
      setHasValidationError(true);
      return Promise.reject(new Error(""));
    }

    return Promise.resolve();
  };

  // Resets the value to 0% if the user leaves the input field empty
  const handleBlur = (index) => {
    const newValues = [...formValues];
    if (!newValues[index].percentage) {
      form.setFieldsValue({
        [formName]: newValues.map((item, idx) =>
          idx === index ? { ...item, percentage: 0 } : item,
        ),
      });
    }
  };

  return (
    <>
      <Row gutter={[24, 24]} style={{ marginBottom: "10px" }}>
        <Col xs={24} sm={24} md={10} lg={10} xl={10}>
          <div className="op-label">
            *{labelName} of Your Patient Population
          </div>
        </Col>
        <Col xs={9} sm={9} md={9} lg={9} xl={9}>
          <div className="op-label">{labelName}</div>
        </Col>
        <Col>
          <div className="op-label">Proportion</div>
        </Col>
      </Row>
      <Form.List
        name={formName}
        rules={[
          {
            validator: async (_, diversities) => {
              let percentage = 0;

              diversities.forEach((diversity) => {
                percentage += diversity.percentage;
              });

              if (percentage < 100 || percentage > 100) {
                setHasValidationError(true);
                return Promise.reject(new Error(""));
              } else {
                setHasValidationError(false);
              }
            },
          },
        ]}>
        {(fields, { add, remove }, { errors }) => (
          <div>
            {fields.map((field, index) => {
              return (
                <Row
                  id={formName}
                  gutter={[24, 24]}
                  style={{ marginBottom: "10px" }}
                  key={field.key}>
                  <Col xs={24} sm={24} md={10} lg={10} xl={10}></Col>
                  <Col xs={9} sm={9} md={9} lg={9} xl={9}>
                    <div className="pop-reg-666">{formValues[index].name}</div>
                  </Col>
                  <Col span={4}>
                    <Form.Item
                      name={[field.name, "percentage"]}
                      validateTrigger={["onChange", "onBlur"]}
                      rules={[
                        {
                          validator: validatePercentage,
                        },
                      ]}>
                      <InputNumber
                        className={
                          hasValidationError ||
                          formValues[index].percentage === "" ||
                          formValues[index].percentage === null
                            ? "crio-input crio-input-with-error"
                            : "crio-input"
                        }
                        min={0}
                        max={100}
                        precision={0}
                        formatter={(value) => {
                          return `${value}%`;
                        }}
                        parser={(value) => value.replace("%", "")}
                        onBlur={() => handleBlur(index)}
                      />
                    </Form.Item>
                    {index === formValues.length - 1 && (
                      <div style={{ width: "90%" }}>
                        <div style={{ marginTop: 8 }}>{""}</div>
                        <Divider
                          type="horizontal"
                          className="horizontal-divider-class"
                        />
                      </div>
                    )}
                  </Col>
                </Row>
              );
            })}
            <Form.ErrorList errors={errors} />
          </div>
        )}
      </Form.List>
      <Row gutter={[24, 24]} style={{ marginBottom: "10px" }}>
        <Col xs={24} sm={24} md={10} lg={10} xl={10}>
          <div></div>
        </Col>
        <Col xs={9} sm={9} md={9} lg={9} xl={9}>
          <div></div>
        </Col>
        <Col>
          <span
            style={{ marginLeft: 10, marginBottom: 0 }}
            className="pop-reg-666">
            {totalPercentage}%
          </span>
          <div
            className="op-label"
            style={{
              marginLeft: 10,
              marginTop: 5,
            }}>
            <p
              style={{
                marginTop: 2,
                color: hasValidationError ? "#c04040" : "#999999",
                fontWeight: hasValidationError ? 400 : 200,
              }}>
              {100 - totalPercentage}% remaining
            </p>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default DiversityPopulation;
