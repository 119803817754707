import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch } from "react-redux";
import { saveDirtyForm } from "../features/dirtyForm";

/**
 * A custom date picker component that allows the user to select a date from a calendar.
 * @param {{object}} props - The props object that contains the value, onChange, setEdited, and setEditedPi functions.
 * @returns A date picker component with a custom header that allows the user to select a date.
 */
const CrioDatePicker = ({
  dateValue,
  setEdited,
  setEditedPi,
  onChange,
  ...props
}) => {
  const [startDate, setStartDate] = useState();
  const dispatch = useDispatch();

  // Generates a list of numbers from a to b (inclusive).
  const range = (a, b) => {
    let list = [];
    for (let i = a; i <= b; i++) {
      list.push(i);
    }
    return list;
  };
  const years = range(1900, new Date().getFullYear());
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  useEffect(() => {
    if (dateValue) {
      setStartDate(props.value);
    }
  }, [dateValue, props.value]);
  return (
    <DatePicker
      dateFormat={"dd/MM/yyyy"}
      style={{
        maxWidth: "250px",
        width: "100%",
      }}
      className={props.value ? "" : "pi-date-picker-error"}
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <div
          style={{
            margin: 10,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div
            onClick={decreaseMonth}
            disabled={prevMonthButtonDisabled}
            className="datepicker-left-button"
          >
            {"<"}
          </div>
          <select
            value={months[date.getMonth()]}
            onChange={({ target: { value } }) =>
              changeMonth(months.indexOf(value))
            }
            className="crio-input date-picker-select"
          >
            {months.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
          <select
            value={date.getFullYear()}
            onChange={({ target: { value } }) => changeYear(value)}
            className="crio-input date-picker-select"
          >
            {years.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
          <div
            onClick={() => increaseMonth()}
            disabled={nextMonthButtonDisabled}
            className="datepicker-right-button"
          >
            {">"}
          </div>
        </div>
      )}
      selected={startDate}
      onChange={(e) => {
        setStartDate(e);
        onChange(e);
        setEdited(true);
        dispatch(saveDirtyForm(true));
        setEditedPi(true);
      }}
    />
  );
};

export default CrioDatePicker;
