import React from "react";
import { Modal, Spin } from "antd";

/**
 * A functional component that displays a loading spinner in a modal.
 * @param {{boolean}} open - A boolean value that determines whether the modal is open or not.
 */
const LoadingModal = ({ open }) => {
  return (
    <>
      <Modal
        open={open}
        footer={null}
        closable={false}
        centered
        style={{ maxWidth: "350px" }}
      >
        <div className="spinner-loader">
          <Spin size="large" />
          Loading, please wait...
        </div>
      </Modal>
    </>
  );
};
export default LoadingModal;
