import { notification, Row } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { saveOrganization } from "../../../features/organization";
import OrganizationService from "../../../services/organizationService";
import PremiumModal from "./PremiumModal";
import Sidebar from "./Sidebar";
import OrgProfileForm from "./OrgProfileForm/OrgProfileForm";

/**
 * A functional component that renders the organization profile page.
 * @returns The organization profile page UI.
 */
const OrgProfile = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [premiumChecked, setPremiumChecked] = useState(false);
  let organizationData = useSelector((state) => state.organization);

  /**
   * Accepts the premium service for the current organization.
   * If successful, updates the organization data in the store.
   * If unsuccessful, displays an error notification.
   */
  const acceptPremium = () => {
    OrganizationService.acceptService(organizationData.organizationId)
      .then((res) => {
        let orgData = Object.assign({}, organizationData);
        orgData.serviceOpted = 1;
        dispatch(saveOrganization(orgData));
      })
      .catch(() => {
        notification.error({
          message: "Something went wrong. Please refresh the page.",
        });
      });
  };

  return (
    <>
      <PremiumModal open={open} setOpen={setOpen} />
      <div className="body-container">
        <Row gutter={[24, 24]}>
          <Sidebar
            organizationData={organizationData}
            setOpen={setOpen}
            premiumChecked={premiumChecked}
            setPremiumChecked={setPremiumChecked}
            acceptPremium={acceptPremium}
          />

          <OrgProfileForm
            organizationData={organizationData}
            setPremiumChecked={setPremiumChecked}
          />
        </Row>
      </div>
    </>
  );
};

export default OrgProfile;
