import React, { useEffect, useState } from "react";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EditOrgProfile from "./EditOrgProfile";
import EditSiteProfile from "./EditSiteProfile";
import LoadingModal from "./LoadingModal";

/**
 * A component that displays a stepper with two steps. The active step is controlled by the parent component
 * through the `step` prop. The component also renders the content of each step based on the active step.
 * @param {{number}} step - The active step of the stepper.
 */
const Stepper = ({ step }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [step2, setStep2] = useState(false);
  const handleClick = () => {
    setActiveStep(0);
  };
  const handleClick2 = () => {
    setActiveStep(1);
  };

  /**
   * useEffect hook that is triggered when the step state changes. It scrolls the window to the top,
   * sets the active step to the current step, and sets the step2 state to true if the current step is 1.
   * @param {{number}} step - the current step in the process
   */
  useEffect(() => {
    window.scrollTo(0, 0);
    setActiveStep(step);
    if (step === 1) {
      setStep2(true);
    }
  }, [step]);

  /**
   * Returns the content for a given step index.
   * @param {{number}} stepIndex - The index of the step to get the content for.
   * @returns The content for the given step index.
   */
  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <EditOrgProfile
            handleClick={handleClick2}
            setLoaderLoading={setLoading}
            setStep2={setStep2}
          />
        );
      case 1:
        return (
          <EditSiteProfile
            handleClick={handleClick}
            setLoaderLoading={setLoading}
          />
        );
      default:
        return "No Content";
    }
  };

  return (
    <>
      <LoadingModal open={loading} />
      <div className="body-container-setup">
        <div className="body-container-setup-header">
          <div className="step-wrapper">
            <div className="step-1" onClick={handleClick}>
              {activeStep === 1 ? (
                <div className={activeStep === 1 ? "step-1-icon" : "normal"}>
                  <FontAwesomeIcon icon={faCheck} />
                </div>
              ) : (
                ""
              )}

              <div
                className={activeStep === 0 ? "step-active" : "step-progress"}
              >
                <p>STEP 1</p>
                <h3>ORGANIZATION PROFILE</h3>
              </div>
              <div
                className={activeStep === 0 ? "step-dots" : "step-green-dots"}
              >
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
              </div>
            </div>
            <div
              className="step-2"
              onClick={() => {
                if (step2) {
                  handleClick2();
                }
              }}
            >
              <div
                className={activeStep === 1 ? "step-2-active" : "step-inactive"}
              >
                <p>STEP 2</p>
                <h3>SITE PROFILE</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="content-area">{getStepContent(activeStep)}</div>
    </>
  );
};

export default Stepper;
