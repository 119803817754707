import React, { useEffect, useRef, useState } from "react";
import { faAngleLeft, faAngleRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col, Carousel } from "antd";

/**
 * A component that displays a carousel of images for a site.
 * @param {{siteImages}} siteImages - An array of image objects for the site.
 * @returns A carousel of images for the site.
 */
function AboutSiteImageCarousel({ siteImages }) {
  const imgRef = useRef();
  const [imgList, setImgList] = useState([]);
  const [shownCt, setShownCt] = useState(0);

  // Calculates the count of items in a list up to the current index.
  const getCount = (current, list) => {
    let ct = 0;
    for (let i = 0; i <= current; i++) {
      ct += list[i].length;
    }
    setShownCt(ct);
  };

  // useEffect hook that updates the image list and count when siteImages changes.
  useEffect(() => {
    if (siteImages) {
      let list = [];
      for (let i = 0; i < siteImages.length; i += 9) {
        list.push(siteImages.slice(i, i + 9));
      }
      setImgList(list);
      getCount(imgRef.current.innerSlider.state.currentSlide, list);
    }
  }, []);
  return (
    <div className="search-site-about-img">
      <Carousel ref={imgRef} dots={false}>
        {imgList.map((groupList) => {
          return (
            <Col key={groupList[0].imageUrl}>
              <Row>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Row>
                    <Col span={16}>
                      {groupList[0] ? (
                        <div className="search-site-card-imgs-big">
                          <img
                            src={groupList[0] ? groupList[0].imageUrl : null}
                            alt="Org Photo"
                          />
                        </div>
                      ) : null}
                    </Col>
                    <Col span={8}>
                      <Row>
                        {groupList[1] ? (
                          <div className="search-site-card-imgs-small">
                            <img
                              src={groupList[1] ? groupList[1].imageUrl : null}
                              alt="Org Photo"
                            />
                          </div>
                        ) : null}
                      </Row>
                      <Row>
                        {groupList[2] ? (
                          <div className="search-site-card-imgs-small">
                            <img
                              src={groupList[2] ? groupList[2].imageUrl : null}
                              alt="Org Photo"
                            />
                          </div>
                        ) : null}
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Row>
                    <Col span={8}>
                      <Row>
                        {groupList[3] ? (
                          <div className="search-site-card-imgs-small">
                            <img
                              src={groupList[3] ? groupList[3].imageUrl : null}
                              alt="Org Photo"
                            />
                          </div>
                        ) : null}
                      </Row>
                      <Row>
                        {groupList[4] ? (
                          <div className="search-site-card-imgs-small">
                            <img
                              src={groupList[4] ? groupList[4].imageUrl : null}
                              alt="Org Photo"
                            />
                          </div>
                        ) : null}
                      </Row>
                    </Col>
                    <Col span={8}>
                      <Row>
                        {groupList[5] ? (
                          <div className="search-site-card-imgs-small">
                            <img
                              src={groupList[5] ? groupList[5].imageUrl : null}
                              alt="Org Photo"
                            />
                          </div>
                        ) : null}
                      </Row>
                      <Row>
                        {groupList[6] ? (
                          <div className="search-site-card-imgs-small">
                            <img
                              src={groupList[6] ? groupList[6].imageUrl : null}
                              alt="Org Photo"
                            />
                          </div>
                        ) : null}
                      </Row>
                    </Col>
                    <Col span={8}>
                      <Row>
                        {groupList[7] ? (
                          <div className="search-site-card-imgs-small">
                            <img
                              src={groupList[7] ? groupList[7].imageUrl : null}
                              alt="Org Photo"
                            />
                          </div>
                        ) : null}
                      </Row>
                      <Row>
                        {groupList[8] ? (
                          <div className="search-site-card-imgs-small">
                            <img
                              src={groupList[8] ? groupList[8].imageUrl : null}
                              alt="Org Photo"
                            />
                          </div>
                        ) : null}
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          );
        })}
      </Carousel>
      <div className="site-about-img-left-right-wrapper">
        <div className="site-about-img-left-right">
          <div
            onClick={() => {
              imgRef.current.prev();
              if (imgRef.current.innerSlider.state.currentSlide === 0) {
                getCount(imgList.length - 1, imgList);
              } else {
                getCount(
                  imgRef.current.innerSlider.state.currentSlide - 1,
                  imgList
                );
              }
            }}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
          </div>
          <div>
            <h6>
              {shownCt}/{siteImages.length}
            </h6>
          </div>
          <div
            onClick={() => {
              imgRef.current.next();
              if (
                imgRef.current.innerSlider.state.currentSlide ===
                imgList.length - 1
              ) {
                getCount(0, imgList);
              } else {
                getCount(
                  imgRef.current.innerSlider.state.currentSlide + 1,
                  imgList
                );
              }
            }}
          >
            <FontAwesomeIcon icon={faAngleRight} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutSiteImageCarousel;
