import React, { useEffect, useState } from "react";
import { Skeleton } from "antd";
import logo from "../../assets/images/CRIO_logo_notagline-nocolor.png";
import OrganizationService from "../services/organizationService";

/**
 * A functional component that renders the footer of the page. It retrieves the copyRight information
 * from the OrganizationService and displays it in the footer.
 */
const Footer = () => {
  const [copyRight, setCopyRight] = useState("");
  useEffect(() => {
    OrganizationService.getCopyright().then((res) => {
      setCopyRight(res.data.data.copyright);
    });
  }, []);
  return (
    <>
      <div className="footer">
        <div className="footer-branding">
          <img src={logo} alt="footerlogo" />
        </div>
        <div className="footer-rights">
          <a
            href={process.env.REACT_APP_SECURITY}
            target="_blank"
            rel="noopener noreferrer"
          >
            Security & Compliance
          </a>
          <a
            href={process.env.REACT_APP_PRIVACY}
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
          {copyRight !== "" ? (
            <p rel="noopener noreferrer">{copyRight}</p>
          ) : (
            <Skeleton.Input />
          )}
        </div>
      </div>
    </>
  );
};

export default Footer;
