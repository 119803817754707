import React from "react";
import { Button, Col, Modal, Row } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/pro-solid-svg-icons";

/**
 * A modal component that displays a warning message when the user tries to leave a page
 * without saving their changes.
 */
const DraftWarningModal = ({
  open,
  exitTab,
  saveTab,
  setShowWarning,
  setUpcomingTab,
}) => {
  return (
    <>
      <Modal
        open={open}
        footer={null}
        centered
        style={{ maxWidth: "550px" }}
        onCancel={() => {
          setShowWarning(-1);
          setUpcomingTab(-1);
        }}
        maskClosable={false}>
        <Row>
          <Col span={3}>
            <FontAwesomeIcon
              icon={faExclamationCircle}
              color={"#009ceb"}
              fontSize={50}
            />
          </Col>
          <Col span={18}>
            <div className="warning-right-header">
              Save Changes Before Leaving
            </div>
            <div className="warning-right-body">
              Leaving this page without saving will abandon any unsaved edits
            </div>
            <div className="warning-footer">
              <Button className="warning-footer-left" onClick={() => saveTab()}>
                Save and Exit
              </Button>
              <Button
                className="warning-footer-right"
                onClick={() => exitTab()}>
                Leave Without Saving
              </Button>
            </div>
          </Col>
        </Row>
      </Modal>
    </>
  );
};
export default DraftWarningModal;
