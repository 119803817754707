import { createSlice } from "@reduxjs/toolkit";

// A Redux slice that manages the number of studies in the application state.
export const noOfStudiesSlice = createSlice({
  name: "noOfStudies",
  initialState: [],
  reducers: {
    saveNoOfStudies: (state, action) => {
      return action.payload;
    },
    removeNoOfStudies: (state, action) => {
      return [];
    },
  },
});

export const { saveNoOfStudies, removeNoOfStudies } = noOfStudiesSlice.actions;
export default noOfStudiesSlice.reducer;
