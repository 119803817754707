import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  faCaretDown,
  faQuestionCircle,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Col,
  Form,
  Input,
  notification,
  Row,
  Select,
  Skeleton,
  Space,
  Tooltip,
} from "antd";
import util from "../../utils/util";
import { updateSite } from "../features/sites";
import OrganizationService from "../services/organizationService";
import SitePageService from "../services/SitePageService";
import DraftWarningModal from "./DraftWarningModal";
// import ErrorFieldModal from "./ErrorFieldModal";
import UpdateLogoModal from "./UpdateLogoModal";
import LoadingModal from "./LoadingModal";
import CrioDatePicker from "./CrioDatePicker";
import { saveDirtyForm } from "../features/dirtyForm";

// A component that renders a form for editing a single principal investigator's profile.
function SinglePIProfileEdit({
  piData,
  pis,
  setPis,
  siteData,
  setActiveTab,
  last,
  setActiveKey,
  setEdited,
  showWarning,
  setShowWarning,
  upcomingTab,
  setUpcomingTab,
}) {
  const [style, setStyle] = useState({ display: "none" });
  const [photo, setPhoto] = useState();
  const [loading, setLoading] = useState(false);
  const areasOption = useSelector((state) => state.areas);
  const studiesnumber = useSelector((state) => state.noOfStudies);
  const boardCerts = useSelector((state) => state.boardCert);
  const [editedPi, setEditedPi] = useState(false);
  // const [open, setOpen] = useState(false);
  // const [errors, setErrors] = useState([]);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [wordCount, setWordCount] = useState(0);

  const handleTextAreaChange = (e) => {
    const { value } = e.target;
    let words = [];
    if (value !== "") {
      words = value.trim().split(/\s+/);
    }
    if (words.length === 200 && value.charAt(value.length - 1) === " ") {
      setWordCount(words.length + 1);
    } else {
      setWordCount(words.length);
    }
  };
  const validateWordLimit = (_, value) => {
    if (
      value &&
      value.trim().split(/\s+/).length === 200 &&
      value.charAt(value.length - 1) === " "
    ) {
      return Promise.reject("Maximum word limit exceeded (200 words).");
    }
    if (value && value.trim().split(/\s+/).length > 200) {
      return Promise.reject("Maximum word limit exceeded (200 words).");
    }
    return Promise.resolve();
  };

  // Handles the submission of the form data for updating a principal investigator's profile.
  const onFinish = (e) => {
    setLoading(true);
    let payload = util.getPiPayload(
      piData,
      e,
      areasOption,
      studiesnumber,
      boardCerts
    );
    OrganizationService.updatePi(piData.principalInvestigatorId, payload)
      .then((res) => {
        setPiDataInList(res);
        notification.success({
          message: piData.piName + " profile successfully updated ...",
        });
        OrganizationService.fetchPiPhoto(piData.principalInvestigatorId).then(
          (res) => {
            setPhoto(res.data.data);
          }
        );
        SitePageService.getSiteInfoForPer(siteData.siteId)
          .then((res2) => {
            let temp = Object.assign({}, siteData);
            temp.percentageComplete = res2.data.data.percentageComplete;
            temp.isActive = res2.data.data.isActive;
            dispatch(updateSite(temp));
            setActiveKey(-1);
            if (last) {
              setActiveTab(upcomingTab === -1 ? "3" : upcomingTab);
            }
            setEdited(false);
            dispatch(saveDirtyForm(false));
            setEditedPi(false);
            setLoading(false);
            setShowWarning(-1);
          })
          .catch((err) => {
            notification.error({
              message: "Something went wrong ! Please refresh the page...",
            });
            setLoading(false);
          });
      })
      .catch(() => {
        notification.error({ message: "PI profile update failed !!!" });
        setLoading(false);
      });
  };

  /**
   * Updates the list of principal investigators with the new data from the response.
   * @param {{object}} res - The response object containing the updated data for a principal investigator.
   */
  const setPiDataInList = (res) => {
    let tempPis = pis.map((el) => {
      if (
        res.data.data.principalInvestigatorId === el.principalInvestigatorId
      ) {
        return res.data.data;
      }
      return el;
    });
    setPis(tempPis);
  };

  /**
   * Uploads an image to the server for the given principal investigator and updates the state of the page.
   * @param {{fmData}} fmData - The image data to upload.
   */
  const uploadImage = async (fmData) => {
    return await OrganizationService.uploadPiPhoto(
      piData.principalInvestigatorId,
      fmData
    )
      .then((res) => {
        setPiDataInList(res);
        OrganizationService.fetchPiPhoto(piData.principalInvestigatorId).then(
          (res) => {
            setPhoto(res.data.data);
          }
        );
        SitePageService.getSiteInfoForPer(siteData.siteId)
          .then((res2) => {
            let temp = Object.assign({}, siteData);
            temp.percentageComplete = res2.data.data.percentageComplete;
            temp.isActive = res2.data.data.isActive;
            dispatch(updateSite(temp));
          })
          .catch((err) => {
            notification.error({
              message: "Something went wrong ! Please refresh the page...",
            });
          });
      })
      .catch(() => {
        notification.error({ message: "Uploading profile picture failed !!!" });
      });
  };

  // Deletes the profile picture of the principal investigator associated with the current organization.
  const deletePiProfilePic = async () => {
    return await OrganizationService.deletePiProfilePic(
      piData.principalInvestigatorId
    )
      .then((res) => {
        let tempPi = { data: { data: Object.assign({}, piData) } };
        tempPi.data.data.profilePicture = null;
        tempPi.data.data.profilePercentage = res.data.data.profilePercentage;
        setPiDataInList(tempPi);
        setPhoto(null);
        SitePageService.getSiteInfoForPer(siteData.siteId)
          .then((res2) => {
            let temp = Object.assign({}, siteData);
            temp.percentageComplete = res2.data.data.percentageComplete;
            temp.isActive = res2.data.data.isActive;
            dispatch(updateSite(temp));
          })
          .catch((err) => {
            notification.error({
              message: "Something went wrong ! Please refresh the page...",
            });
          });
      })
      .catch(() => {
        notification.error({ message: "Deleting profile picture failed !!!" });
      });
  };

  /**
   * Saves the current tab by validating the form fields and calling the onFinish function with the form values.
   * If the upcomingTab is -1, setActiveKey is set to -1, otherwise setActiveTab is called with upcomingTab.
   * The edited state is set to false and the showWarning state is set to -1.
   * If there are any errors, the showWarning state is set to -1, the upcomingTab is set to -1, the form is scrolled to the first error field,
   * the errors state is set to the error fields, and the open state is set to true.
   */
  const saveTab = () => {
    onFinish(form.getFieldsValue());
  };

  // Resets the state variables related to the currently edited Principal Investigator and closes the Principal Investigator editor.
  const exitTabPi = () => {
    setEditedPi(false);
    setEdited(false);
    dispatch(saveDirtyForm(false));
    setShowWarning(-1);
    if (upcomingTab === -1) {
      setActiveKey(-1);
    } else {
      setActiveTab(upcomingTab);
    }
  };

  /**
   * useEffect hook that fetches the principal investigator's photo and sets the form fields
   * with the PI's data.
   * @param {{Object}} piData - The principal investigator's data object.
   */
  useEffect(() => {
    if (piData) {
      piData.piDescription &&
        setWordCount(piData.piDescription.trim().split(/\s+/).length);
      OrganizationService.fetchPiPhoto(piData.principalInvestigatorId).then(
        (res) => {
          setPhoto(res.data.data);
        }
      );
      let formData = util.setPiProfile(piData, areasOption, boardCerts);
      form.setFields(formData);
      form.validateFields();
    }
  }, []);
  return (
    <>
      <DraftWarningModal
        open={showWarning === "2" && editedPi ? true : false}
        exitTab={exitTabPi}
        saveTab={saveTab}
        setShowWarning={setShowWarning}
        setUpcomingTab={setUpcomingTab}
      />
      <LoadingModal open={loading} />
      {/* <ErrorFieldModal open={open} setOpen={setOpen} errors={errors} /> */}
      <Form
        form={form}
        onFinish={onFinish}
        style={{ margin: "20px" }}
        onChange={(e) => {
          if (e.target.className !== "crio-input date-picker-select") {
            setEdited(true);
            dispatch(saveDirtyForm(true));
            setEditedPi(true);
          }
        }}
        onFinishFailed={() => {
          let errList = [];
          form.getFieldsError().forEach((el) => {
            if (el.errors.length !== 0) {
              errList.push(el);
            }
          });
          onFinish(form.getFieldsValue());
        }}
      >
        <Row gutter={[24, 24]}>
          <Col span={10}>
            <div className="edit-site-form-label">Profile Picture</div>
          </Col>
          <Col span={14}>
            <div className="edit-site-logo">
              {photo ? (
                <img
                  src={photo}
                  alt="PI Image"
                  onMouseEnter={() => {
                    setStyle({
                      display: "flex",
                      margin: "20px 10px",
                      height: "-webkit-fill-available",
                      width: "-webkit-fill-available",
                      color: "white",
                    });
                  }}
                />
              ) : (
                <div
                  onMouseEnter={() => {
                    setStyle({
                      display: "flex",
                      margin: "20px 10px",
                      height: "-webkit-fill-available",
                      width: "-webkit-fill-available",
                      color: "white",
                    });
                  }}
                >
                  <Skeleton.Image />{" "}
                </div>
              )}
              <UpdateLogoModal
                style={style}
                setStyle={setStyle}
                uploadLogoData={uploadImage}
                titleText={"Profile Picture"}
                deleteImage={deletePiProfilePic}
                deleteOption={piData.profilePicture ? true : false}
                img={piData.profilePicture ? photo : false}
              />
            </div>
          </Col>
        </Row>
        <Row gutter={[24, 24]} style={{ marginBottom: "20px" }}>
          <Col xs={24} sm={24} md={10} lg={10} xl={10}>
            <div className="op-label">PI Name</div>
          </Col>
          <Col xs={24} sm={24} md={14} lg={14} xl={14}>
            <div className="op-label">{piData.piName}</div>
          </Col>
        </Row>
        <Row gutter={[24, 24]} style={{ marginBottom: "20px" }}>
          <Col xs={24} sm={24} md={10} lg={10} xl={10}>
            <div className="op-label">* Date Started as PI</div>
          </Col>
          <Col xs={24} sm={24} md={14} lg={14} xl={14}>
            <div className="op-label">
              <Form.Item
                name={"startingDate"}
                rules={[
                  {
                    required: true,
                    message: "Please enter Date Started as PI",
                  },
                ]}
              >
                <CrioDatePicker
                  setEdited={setEdited}
                  setEditedPi={setEditedPi}
                  dateValue={piData.startingDate}
                />
              </Form.Item>
            </div>
          </Col>
        </Row>
        <Row gutter={[24, 24]} style={{ marginBottom: "20px" }}>
          <Col xs={24} sm={24} md={10} lg={10} xl={10}>
            <div className="op-label">* Therapeutic Areas</div>
          </Col>
          <Col xs={24} sm={24} md={14} lg={14} xl={14}>
            <div className="op-label">
              <Form.Item
                name={"therapeuticAreaIndicationIds"}
                rules={[
                  {
                    required: true,
                    message: "Please Select Therapeutic Areas",
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  className="crio-input"
                  placeholder="-Select Therapeutic Areas-"
                  getPopupContainer={(trigger) => trigger.parentNode}
                  suffixIcon={<FontAwesomeIcon icon={faCaretDown} />}
                  showArrow
                  maxTagCount="responsive"
                  onChange={() => {
                    setEdited(true);
                    dispatch(saveDirtyForm(true));
                    setEditedPi(true);
                  }}
                >
                  {areasOption.map((el) => {
                    return (
                      <Select.Option
                        value={el.therapeuticName}
                        key={el.therapeuticId}
                      />
                    );
                  })}
                </Select>
              </Form.Item>
            </div>
          </Col>
        </Row>
        <Row gutter={[24, 24]} style={{ marginBottom: "20px" }}>
          <Col xs={24} sm={24} md={10} lg={10} xl={10}>
            <div className="op-label">
              * Professional Certifications{" "}
              <Tooltip
                title={
                  <div className="certification-tooltip">
                    <p>Certification Not on the List?</p>
                    If your board certifications are not on the list, please
                    pick the categories that are closest to yours. If you are
                    not in the US, pick the equivalents.
                  </div>
                }
                placement="bottom"
                arrowPointAtCenter
                overlayInnerStyle={{
                  color: "#666666",
                  backgroundColor: "#fff",
                  padding: "20px 20px",
                  fontSize: "14px",
                  minWidth: "280px",
                }}
                overlayStyle={{
                  backgroundColor: "#fff",
                  "--antd-arrow-background-color": "#fff",
                }}
              >
                <FontAwesomeIcon
                  color="#009ceb"
                  icon={faQuestionCircle}
                  fontSize={22}
                  className="certification-tooltip-icon"
                />
              </Tooltip>
            </div>
          </Col>
          <Col xs={24} sm={24} md={14} lg={14} xl={14}>
            <div className="op-label">
              <Form.Item
                name={"boardCertification"}
                rules={[
                  {
                    required: true,
                    message: "Please Select Professional Certifications",
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  className="crio-input"
                  placeholder="-Select all that apply-"
                  getPopupContainer={(trigger) => trigger.parentNode}
                  suffixIcon={<FontAwesomeIcon icon={faCaretDown} />}
                  showArrow
                  maxTagCount="responsive"
                  onChange={() => {
                    setEdited(true);
                    dispatch(saveDirtyForm(true));
                    setEditedPi(true);
                  }}
                >
                  {boardCerts.map((el) => {
                    return <Select.Option value={el.value} key={el.lookupId} />;
                  })}
                </Select>
              </Form.Item>
            </div>
          </Col>
        </Row>
        <Row gutter={[24, 24]} style={{ marginBottom: "20px" }}>
          <Col xs={24} sm={24} md={10} lg={10} xl={10}>
            <div className="op-label" style={{ maxWidth: "250px" }}>
              * Number of Studies Total number of studies worked on as a PI
            </div>
          </Col>
          <Col xs={24} sm={24} md={14} lg={14} xl={14}>
            <div className="op-label">
              <Form.Item
                name={"numOfStudies"}
                rules={[
                  {
                    required: true,
                    message: "Please Select total number of studies",
                  },
                ]}
              >
                <Select
                  className="crio-input"
                  placeholder="-Select-"
                  style={{ maxWidth: "200px" }}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  suffixIcon={<FontAwesomeIcon icon={faCaretDown} />}
                  onChange={() => {
                    setEdited(true);
                    dispatch(saveDirtyForm(true));
                    setEditedPi(true);
                  }}
                >
                  {studiesnumber.map((el) => {
                    return <Select.Option value={el.value} key={el.lookupId} />;
                  })}
                </Select>
              </Form.Item>
            </div>
          </Col>
        </Row>
        <Row gutter={[24, 24]} style={{ marginBottom: "20px" }}>
          <Col xs={24} sm={24} md={10} lg={10} xl={10}>
            <div className="op-label">PI Description</div>
          </Col>
          <Col xs={24} sm={24} md={14} lg={14} xl={14}>
            <div className="op-label">
              <Form.Item
                name={"piDescription"}
                rules={[
                  {
                    validator: validateWordLimit,
                  },
                ]}
              >
                <Input.TextArea
                  bordered={false}
                  onChange={handleTextAreaChange}
                  className="crio-input"
                  style={{
                    height: 120,
                    resize: "none",
                    fontSize: "14px",
                    fontFamily: ["Poppins-Light", "Poppins Light", "Poppins"],
                    fontWeight: "200",
                    color: "#999999 !important",
                    width: "100%",
                  }}
                  placeholder="Enter Description (Max 200 words)"
                />
              </Form.Item>
              <div className="crio-word-count">{wordCount} / 200</div>
            </div>
          </Col>
        </Row>
        <Row gutter={[24, 24]} style={{ marginBottom: "20px" }}>
          <Col xs={24} sm={24} md={10} lg={10} xl={10}>
            <Space>
              <Button
                htmlType="submit"
                className={editedPi ? "save-pi-button" : "cancel-pi-button"}
                loading={loading}
                disabled={!editedPi}
              >
                Save
              </Button>
              <Button
                type="secondary"
                onClick={() => {
                  setUpcomingTab(-1);
                  if (editedPi) {
                    setShowWarning("2");
                  } else {
                    setActiveKey(-1);
                  }
                }}
                className="cancel-pi-button"
                style={{ backgroundColor: "#bfbfbf" }}
              >
                Cancel
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default SinglePIProfileEdit;
