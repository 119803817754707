import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Spin } from "antd";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import { app } from "../../../config/firebaseConfig";
import LoginService from "../../services/loginService";

/**
 * A functional component that handles Firebase authentication for logging in.
 * @returns A div container with a spinning loader.
 */
function LoginFireBase() {
  const auth = getAuth();
  const { orgId } = useParams();

  /**
   * Fetches the authentication token from the cookie of the current document.
   * @returns The authentication token if it exists, otherwise null.
   */
  const fetchAuthToken = () => {
    const cookieString = document.cookie;
    const cookieArray = cookieString.split("; ");
    const authTokenCookie = cookieArray.find((cookie) =>
      cookie.startsWith("siteDirectory-jwt=")
    );
    if (!authTokenCookie) {
      return null;
    }
    const authToken = authTokenCookie.split("=")[1];
    return authToken;
  };

  /**
   * Runs the login process when the component mounts. If a token is found, it will sign in
   * with the custom token and then log the user in. If no token is found, it will log the user out.
   */
  useEffect(() => {
    const token = fetchAuthToken();
    if (token) {
      signInWithCustomToken(auth, token)
        .then((userCredential) => {
          LoginService.login(orgId, userCredential._tokenResponse.idToken)
            .then((response) => {
              localStorage.setItem(
                "SDIR_STARTER_DATA",
                JSON.stringify(response.data.data)
              );
              window.location.href = "/home";
            })
            .catch((error) => {
              LoginService.logout();
            });
        })
        .catch((error) => {
          LoginService.logout();
        });
    } else {
      LoginService.logout();
    }
  });
  return (
    <div className="spin-loader-div-container">
      <Spin size="large" />
    </div>
  );
}

export default LoginFireBase;
