import { createSlice } from "@reduxjs/toolkit";

/**
 * This module contains the Redux slice for the admin state.
 * @module adminSlice
 */
export const adminSlice = createSlice({
  name: "admin",
  initialState: [],
  reducers: {
    saveAdmin: (state, action) => {
      return action.payload;
    },
    removeAdmin: (state, action) => {
      return [];
    },
  },
});

export const { saveAdmin, removeAdmin } = adminSlice.actions;
export default adminSlice.reducer;
