import { createSlice } from "@reduxjs/toolkit";

// This slice of the Redux store manages the state of the dirty form presence.
export const dirtyFormSlice = createSlice({
  name: "dirtyForm",
  initialState: false,
  reducers: {
    saveDirtyForm: (state, action) => {
      return action.payload;
    },
  },
});

export const { saveDirtyForm } = dirtyFormSlice.actions;
export default dirtyFormSlice.reducer;
